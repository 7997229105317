import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";


const initialState = {
    theme: "",
    voiceList: getCommonInitialState(),
    voiceStore: getCommonInitialState(),
    voiceShow: getCommonInitialState(),
    voiceStatus: getCommonInitialState(),
    voiceDelete: getCommonInitialState(),
    recordVoice: getCommonInitialState(),
    recordVoiceList:getCommonInitialState(),
    selectedVoice: null,
};

const VoiceSlice = createSlice({
    name: "voice",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //voiceList
        voiceListStart: (state, action) => { state.voiceList = getCommonStart() },
        voiceListSuccess: (state, action) => { state.voiceList = getCommonSuccess(action.payload) },
        voiceListFailure: (state, action) => { state.voiceList = getCommonFailure(action.payload) },

        //voiceStore
        voiceStoreStart: (state, action) => { state.voiceStore = getCommonStart(action.payload) },
        voiceStoreSuccess: (state, action) => { state.voiceStore = getCommonSuccess(action.payload) },
        voiceStoreFailure: (state, action) => { state.voiceStore = getCommonFailure(action.payload) },

        //voiceShow
        voiceShowStart: (state, action) => { state.voiceShow = getCommonStart() },
        voiceShowSuccess: (state, action) => { state.voiceShow = getCommonSuccess(action.payload) },
        voiceShowFailure: (state, action) => { state.voiceShow = getCommonFailure(action.payload) },

        //voiceStatus
        voiceStatusStart: (state, action) => { state.voiceStatus = getCommonStart() },
        voiceStatusSuccess: (state, action) => { state.voiceStatus = getCommonSuccess(action.payload) },
        voiceStatusFailure: (state, action) => { state.voiceStatus = getCommonFailure(action.payload) },

        //voiceDelete
        voiceDeleteStart: (state, action) => { state.voiceDelete = getCommonStart(action.payload) },
        voiceDeleteSuccess: (state, action) => { state.voiceDelete = getCommonSuccess(action.payload) },
        voiceDeleteFailure: (state, action) => { state.voiceDelete = getCommonFailure(action.payload) },

        //recordVoiceCreate
        recordVoiceCreateStart: (state, action) => { state.recordVoice = getCommonStart() },
        recordVoiceCreateSuccess: (state, action) => { state.recordVoice = getCommonSuccess(action.payload) },
        recordVoiceCreateFailure: (state, action) => { state.recordVoice = getCommonFailure(action.payload) },

        //recordVoiceList
        recordVoiceListStart: (state, action) => { state.recordVoiceList = getCommonStart() },
        recordVoiceListSuccess: (state, action) => { state.recordVoiceList = getCommonSuccess(action.payload) },
        recordVoiceListFailure: (state, action) => { state.recordVoiceList = getCommonFailure(action.payload) },

        //set selected voice
        setSelectedVoice: (state, action) => { state.selectedVoice = action.payload },

    }
});

export const {
    setTheme,
    voiceListStart,
    voiceListSuccess,
    voiceListFailure,
    voiceStoreStart,
    voiceStoreSuccess,
    voiceStoreFailure,
    voiceShowStart,
    voiceShowSuccess,
    voiceShowFailure,
    voiceStatusStart,
    voiceStatusSuccess,
    voiceStatusFailure,
    voiceDeleteStart,
    voiceDeleteSuccess,
    voiceDeleteFailure,
    recordVoiceCreateStart,
    recordVoiceCreateSuccess,
    recordVoiceCreateFailure,
    recordVoiceListStart,
    recordVoiceListSuccess,
    recordVoiceListFailure,
    setSelectedVoice,
} = VoiceSlice.actions;

export default VoiceSlice.reducer;