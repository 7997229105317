import React from "react";
import HomeBannerIndex from "./HomeBannerIndex";
import AboutUsIndex from "./AboutUsIndex";
import HowItsWorkIndex from "./HowItsWorkIndex";
import ServiceIndex from "./ServiceIndex";
import CreateAudioFileIndex from "./CreateAudioFileIndex";
import TrustedByBrandsIndex from "./TrustedByBrandsIndex";
import ShareAudioIndex from "./ShareAudioIndex";
import CTAIndex from "./CTAIndex";
import FAQIndex from "./FAQIndex";
import ContactUsIndex from "./ContactUsIndex";

const LandingPageIndex = (props) => {

  return (
    <>
      <div className="landing-page-sec">
        <HomeBannerIndex/>
        <AboutUsIndex/>
        <HowItsWorkIndex/>
        <ServiceIndex/>
        <CreateAudioFileIndex/>
        <TrustedByBrandsIndex/>
        <ShareAudioIndex/>
        <CTAIndex/>
        <FAQIndex/>
        <ContactUsIndex/>
      </div>
    </>
  );
};

export default LandingPageIndex;
