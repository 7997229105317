import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Pagination,
  Button,
  Modal,
} from "react-bootstrap";
import AddVoiceModal from "../Voice/AddVoiceModal";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVoice, voiceListStart } from "../../store/slices/VoiceSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const ChooseVoiceModal = (props) => {
  const dispatch = useDispatch();
  const [addVoiceModal, setAddVoiceModal] = useState(false);
  const voiceList = useSelector((state) => state.voice.voiceList);
  const [skipRender, setSkipRender] = useState(true);
  const selectedVoice = useSelector((state) => state.voice.selectedVoice);

  const closeAddVoiceModal = () => {
    setAddVoiceModal(false);
  };

  useEffect(() => {
    dispatch(voiceListStart());
  }, []);

  useEffect(()=> {
    if(!skipRender && !voiceList.loading && Object.keys(voiceList.data).length > 0 && voiceList.data.voices.length > 0)
    {
      !selectedVoice && dispatch(setSelectedVoice((voiceList.data.voices[0])));
    } 
    setSkipRender(false);
  }, [voiceList])

  return (
    <>
      <Modal
        className="modal-dialog-center add-voice-modal"
        size="lg"
        centered
        show={props.selectVoice}
        onHide={props.closeSelectVoiceModal}
      >
        <Modal.Body>
          <h4>Select Voice</h4>
          <Button className="modal-close" onClick={props.closeSelectVoiceModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <div className="voicelab-box">
                <div className="voicelab-header-sec">
                  <div className="voicelab-header-left-sec">
                    <h3>Select Voice</h3>
                    <p>Choose a voice from the below added list.</p>
                  </div>
                  <div className="voicelab-add-btn-sec">
                    <Button
                      className="add-voicelab-choose-btn"
                      onClick={() => setAddVoiceModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.998H13V10.998H19V12.998Z"
                          fill="url(#paint0_linear_67_3432)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_67_3432"
                            x1="12"
                            y1="4.998"
                            x2="12"
                            y2="18.998"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#5575FF" />
                            <stop offset="1" stop-color="#8F59FC" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <span>Add Voice</span>
                    </Button>
                  </div>
                </div>
                <div className="choose-voice-audio-list-box">
                  {voiceList.loading ? (
                    <React.Fragment>
                      <Skeleton height={65} />
                      <Skeleton height={65} />
                    </React.Fragment>
                  ) : Object.keys(voiceList.data).length > 0 &&
                    voiceList.data.voices.length > 0 ? (
                    <React.Fragment>
                      {voiceList.data.voices.map((voice, i) => (
                        <div className={`choose-voice-audio-list-card ${(selectedVoice?.id == voice.id) && "active"}`} key={i} onClick={()=> dispatch(setSelectedVoice(voice))}>
                          <div className="choose-voice-audio-list-left-sec">
                            <h4 style={{marginBottom:"0"}}>{voice.name}</h4>
                          </div>
                          <div className="choose-voice-audio-list-right-sec">
                            {/* <Button className="voicelab-list-action-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-player-play-filled"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path
                                  d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"
                                  stroke-width="0"
                                  fill="#5575FF"
                                />
                              </svg>
                            </Button> */}
                          </div>
                        </div>))}
                    </React.Fragment>
                  ) : (
                    <NoDataFound />
                  )}

                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {addVoiceModal && (
        <AddVoiceModal
          addVoiceModal={addVoiceModal}
          closeAddVoiceModal={closeAddVoiceModal}
          setAddVoiceModal={setAddVoiceModal}
        />
      )}
    </>
  );
};

export default ChooseVoiceModal;
