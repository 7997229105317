import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ShareAudioIndex = (props) => {
  return (
    <>
      <div className="share-audio-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>Share audio across multiple platforms</h2>
                <p>
                  The converted audio files can be shared on any platform
                  worldwide.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="share-audio-box">
                <div className="share-audio-card">
                  <Image
                    className="share-audio-icon"
                    src={window.location.origin + "/img/share-audio/tiktok.svg"}
                    type="image/png"
                  />
                </div>
                <div className="share-audio-card">
                  <Image
                    className="share-audio-icon"
                    src={
                      window.location.origin + "/img/share-audio/instagram.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="share-audio-card">
                  <Image
                    className="share-audio-icon"
                    src={
                      window.location.origin + "/img/share-audio/discord.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="share-audio-card">
                  <Image
                    className="share-audio-icon"
                    src={
                      window.location.origin + "/img/share-audio/facebook.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="share-audio-card">
                  <Image
                    className="share-audio-icon"
                    src={
                      window.location.origin + "/img/share-audio/youtube.svg"
                    }
                    type="image/png"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShareAudioIndex;
