import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  generatedVoiceList: getCommonInitialState(),
  generatedVoiceStore: getCommonInitialState(),
  generatedVoiceDelete: getCommonInitialState(),
  generatedVoiceDetails: getCommonInitialState(),
  generatedVoiceStatus: getCommonInitialState(),
};

const GeneratedSlice = createSlice({
  name: "generated",
  initialState,
  reducers: {
    // generated voice list
    generatedVoiceListStart: (state) => { state.generatedVoiceList = getCommonStart() },
    generatedVoiceListSuccess: (state, action) => { state.generatedVoiceList = getCommonSuccess(action.payload) },
    generatedVoiceListFailure: (state, action) => { state.generatedVoiceList = getCommonFailure(action.payload) },

    // generated voice store
    generatedVoiceStoreStart: (state) => { state.generatedVoiceStore = getCommonStart() },
    generatedVoiceStoreSuccess: (state, action) => { state.generatedVoiceStore = getCommonSuccess(action.payload) },
    generatedVoiceStoreFailure: (state, action) => { state.generatedVoiceStore = getCommonFailure(action.payload) },

    // generated voice delete
    generatedVoiceDeleteStart: (state, action) => { state.generatedVoiceDelete = getCommonStart(action.payload) },
    generatedVoiceDeleteSuccess: (state, action) => { state.generatedVoiceDelete = getCommonSuccess(action.payload) },
    generatedVoiceDeleteFailure: (state, action) => { state.generatedVoiceDelete = getCommonFailure(action.payload) },

    // generated voice details
    generatedVoiceDetailsStart: (state) => { state.generatedVoiceDetails = getCommonStart() },
    generatedVoiceDetailsSuccess: (state, action) => { state.generatedVoiceDetails = getCommonSuccess(action.payload) },
    generatedVoiceDetailsFailure: (state, action) => { state.generatedVoiceDetails = getCommonFailure(action.payload) },

    // generated voice status
    generatedVoiceStatusStart: (state) => { state.generatedVoiceStatus = getCommonStart() },
    generatedVoiceStatusSuccess: (state, action) => { state.generatedVoiceStatus = getCommonSuccess(action.payload) },
    generatedVoiceStatusFailure: (state, action) => { state.generatedVoiceStatus = getCommonFailure(action.payload) },

  },
});

export const { 
    generatedVoiceListStart,
    generatedVoiceListSuccess,
    generatedVoiceListFailure,
    generatedVoiceStoreStart,
    generatedVoiceStoreSuccess,
    generatedVoiceStoreFailure,
    generatedVoiceDeleteStart,
    generatedVoiceDeleteSuccess,
    generatedVoiceDeleteFailure,
    generatedVoiceDetailsStart,
    generatedVoiceDetailsSuccess,
    generatedVoiceDetailsFailure,
    generatedVoiceStatusStart,
    generatedVoiceStatusSuccess,
    generatedVoiceStatusFailure,
 } = GeneratedSlice.actions;

export default GeneratedSlice.reducer;
