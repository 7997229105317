import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HowItsWorkIndex = (props) => {
  const profile = useSelector((state) => state.admin.profile);

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>Text-to-Speech into action</h2>
                <p>
                  Create brand new, custom voices that can speak multiple
                  languages in different accents.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={6} xl={4} className="resp-mrg-btm-md">
              <div className="how-its-work-card">
                <div className="how-its-work-icon-sec">
                  <Image
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/step-1-icon.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="how-its-work-info">
                  <h6>Step 1</h6>
                  <h4>Choose your preffered voice, settings, and model.</h4>
                  <p>
                    Pick from pre-recorded, cloned or custom voices and
                    fine-tune them for perfect match.
                  </p>
                </div>
                <div className="how-its-work-bottom-img-sec">
                  <Image
                    className="how-its-work-bottom-img"
                    src={
                      window.location.origin + "/img/how-its-work/step-1.png"
                    }
                    type="image/png"
                  />
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} className="resp-mrg-btm-md">
              <div className="how-its-work-card">
                <div className="how-its-work-icon-sec">
                  <Image
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/step-2-icon.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="how-its-work-info">
                  <h6>Step 2</h6>
                  <h4>Enter the text you want to convert to speech.</h4>
                  <p>
                    Compose content effortlessly and naturally in any of our
                    supported languages.
                  </p>
                </div>
                <div className="how-its-work-bottom-img-sec">
                  <Image
                    className="how-its-work-bottom-img"
                    src={
                      window.location.origin + "/img/how-its-work/step-2.png"
                    }
                    type="image/png"
                  />
                </div>
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className="how-its-work-card">
                <div className="how-its-work-icon-sec">
                  <Image
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/step-3-icon.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="how-its-work-info">
                  <h6>Step 3</h6>
                  <h4>Generate spoken audio and preview the outcome.</h4>
                  <p>
                    Convert text into high quality downloadable audio files.
                  </p>
                </div>
                <div className="how-its-work-bottom-img-sec">
                  <Image
                    className="how-its-work-bottom-img"
                    src={
                      window.location.origin + "/img/how-its-work/step-3.png"
                    }
                    type="image/png"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="generate-btn-sec">
                <Link to={Object.keys(profile.data).length > 0 ? "/generated-voice-list" : "/login"} className="default-btn">
                    <span>Generate Now</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowItsWorkIndex;
