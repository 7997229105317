import React from "react";
import { Image } from "react-bootstrap";

const SomethingWentWrong = ({ buttonText = "Refresh", handleClick }) => {

  return (
    <div className="no-bot-exists-card">
      <div className="no-bot-exists-img-sec">
        <Image
          className="no-bot-exists-img"
          src={window.location.origin + "/img/empty/404.png"}
        />
      </div>
      <div className="no-bot-exists-info">
        <h4>Something Went Wrong</h4>
      </div>
      {buttonText ? (
        <button
          className="default-btn"
          onClick={() => handleClick()}
        >
          {buttonText}
        </button>
      ) : null}
    </div>
  );
};

export default SomethingWentWrong;
