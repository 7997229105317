
import LandingFooterIndex from "../layouts/Footer/LandingFooterIndex";
import React, { useEffect, useState } from "react";
import { Form, Button, Image, InputGroup, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVoice, voiceListStart } from "../../store/slices/VoiceSlice";
import VoiceLabEmpty from "../EmptyPages/VoiceLabEmpty";
import VoiceLoader from "../Helper/VoiceLoader";
import DeleteVoiceModal from "./DeleteVoiceModal";
import AddVoiceModal from "./AddVoiceModal";
import { phraseListStart } from "../../store/slices/PhraseSlice";
import { useNavigate } from "react-router-dom";
import EditVoiceModal from "./EditVoiceModal";


const VoiceLabListIndex = (props) => {
  const t = useTranslation("voice_list");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voiceList = useSelector((state) => state.voice.voiceList);
  const [deleteVoiceModal, setDeleteVoiceModal] = useState(false);
  const [addVoiceModal, setAddVoiceModal] = useState(false);
  const [editVoiceModal, setEditVoiceModal] = useState(false);
  const selectedVoice = useSelector((state) => state.voice.selectedVoice);

  useEffect(() => {
    dispatch(voiceListStart())
  }, []);

  const closeDeleteVoiceModal = () => {
    setDeleteVoiceModal(false);
  }

  const closeEditVoiceModal = () => {
    setEditVoiceModal(false);
  }

  const closeAddVoiceModal = () => {
    setAddVoiceModal(false);
  }

  return (
    <>
    {voiceList.loading ? (<VoiceLoader />) :
      (Object.keys(voiceList.data).length > 0 && voiceList.data.voices.length > 0) ? (
        <>
          <div className="landing-page-sec">
            <div className="voicelab-sec">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={8}>
                    <div className="voicelab-box">
                      <h3>{t("title")}</h3>
                      <p>{t("message")}</p>
                      <div className="voicelab-add-btn-sec">
                        <Button className="add-voicelab-btn"  onClick={() => setAddVoiceModal(true)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.998H13V10.998H19V12.998Z"
                              fill="url(#paint0_linear_67_3432)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_67_3432"
                                x1="12"
                                y1="4.998"
                                x2="12"
                                y2="18.998"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#5575FF" />
                                <stop offset="1" stop-color="#8F59FC" />
                              </linearGradient>
                            </defs>
                          </svg>
                          <span>{t("add")}</span>
                        </Button>
                      </div>
                      <div className="voicelab-list-box">
                        {voiceList.data.voices.map(
                          (voice, i) => (
                            <div className="voicelab-list-card" key={i}>
                              <div className="voicelab-list-info">
                                <h4>{voice.name}</h4>
                              </div>
                              <div className="voicelab-list-action-btn-sec">
                                <Button onClick={()=> {
                                  dispatch(setSelectedVoice(voice))
                                  navigate("/generate-voice")
                                }}
                                  className="voicelab-list-action-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 11 14"
                                  >
                                    <path
                                      stroke="url(#paint0_linear_69_3566)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5.5 1.441V12.56M1 4.979V9.148m9-5.56v6.95"
                                    ></path>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_69_3566"
                                        x1="5.5"
                                        x2="5.5"
                                        y1="1.441"
                                        y2="12.559"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#5575FF"></stop>
                                        <stop offset="1" stopColor="#8F59FC"></stop>
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <span>{t("use")}</span>
                                </Button>
                                <Button className="voicelab-list-action-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-player-play"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 4v16l13 -8z" />
                                  </svg>
                                </Button>
                                <Button className="voicelab-list-action-btn" onClick={()=> setEditVoiceModal(voice.unique_id)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-edit"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                    <path d="M16 5l3 3" />
                                  </svg>
                                </Button>
                                <Button className="voicelab-list-action-btn" onClick={() => setDeleteVoiceModal(voice.unique_id)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-trash"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#FF4D4D"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                </Button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <LandingFooterIndex />
          </div>
        </>
      ) : (
        <VoiceLabEmpty addVoiceModal={addVoiceModal} setAddVoiceModal={setAddVoiceModal} />
      )}
      {deleteVoiceModal && <DeleteVoiceModal
        deleteVoiceModal={deleteVoiceModal}
        closeDeleteVoiceModal={closeDeleteVoiceModal}
        setDeleteVoiceModal={setDeleteVoiceModal}
      />}
      {addVoiceModal && <AddVoiceModal
        addVoiceModal={addVoiceModal}
        closeAddVoiceModal={closeAddVoiceModal}
        setAddVoiceModal={setAddVoiceModal}
      />}
      {editVoiceModal && <EditVoiceModal
        addVoiceModal={editVoiceModal}
        closeAddVoiceModal={closeEditVoiceModal}
        setAddVoiceModal={setEditVoiceModal}
      />}
    </>
  )
};

export default VoiceLabListIndex;
