import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  sidebar: true,
  pageData: getCommonInitialState()
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    //set Sidebar
    toggleSidebar: (state, action) => { state.sidebar = !state.sidebar },

    // Static Pages
    fetchStaticPageStart: (state) => { state.pageData = getCommonStart() },
    fetchStaticPageSuccess: (state, action) => { state.pageData = getCommonSuccess(action.payload) },
    fetchStaticPageFailure: (state, action) => { state.pageData = getCommonFailure(action.payload) },

  },
});

export const { 
  toggleSidebar,
  fetchStaticPageFailure,
  fetchStaticPageStart,
  fetchStaticPageSuccess
} = CommonSlice.actions;

export default CommonSlice.reducer;
