import React, { useState } from "react";
import {Container, Row, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQIndex = (props) => {
  return (
    <>
      <div className="faq-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2>FAQ</h2>
                    </div>
                </Col>
            </Row>
            <div className="faq-box">
            <Row className="justify-content-md-center">
              <Col md={12} xl={9}>
                  <Accordion defaultActiveKey="0" className="faq-accordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is Text to Speech Customizable Chat API Agent, and how can it benefit my business?</Accordion.Header>
                      <Accordion.Body>
                      Text to Speech Customizable Chat API Agent is a powerful tool that enables businesses to offer personalized and seamless customer support across multiple channels. It can help increase customer satisfaction, boost conversions, and improve overall business performance.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Can I customize the chat interface to match my website's branding?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Text to Speech Customizable Chat API Agent allows for full customization of the chat interface, including colors, fonts, logos, and more. This ensures that the chat experience seamlessly integrates with your website's branding.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What channels does Text to Speech Customizable Chat API Agent support?</Accordion.Header>
                      <Accordion.Body>
                      Text to Speech Customizable Chat API Agent supports multiple channels, including your website, social media platforms, messaging apps, and more. This enables you to engage with your customers on the platforms they prefer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Is Text to Speech Customizable Chat API Agent easy to integrate with my existing systems?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Text to Speech Customizable Chat API Agent is designed to seamlessly integrate with your existing systems, making it easy to set up and start using.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>What level of customer support does Haive offer for its Customizable Chat API Agent?</Accordion.Header>
                      <Accordion.Body>
                        Haive offers dedicated human support to ensure that your customers receive the best possible experience. Our team is available 24/7 to help with any issues or questions you may have.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Can I track the performance of my chatbot using Text to Speech Customizable Chat API Agent?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Text to Speech Customizable Chat API Agent includes a powerful analytics dashboard that provides real-time insights into customer engagement and behavior. This enables you to track the performance of your chatbot and make data-driven decisions to improve customer support.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
              </Col>
            </Row>
            </div>
        </Container>
      </div>
    </>
  );
};

export default FAQIndex;
