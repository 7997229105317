import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaticPageStart } from "../../store/slices/CommonSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";

const StaticPagesIndex = (props) => {

  const t = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.common.pageData);

  useEffect(() => {
    dispatch(fetchStaticPageStart({ static_page_unique_id: params.unique_id }));
  }, [params.unique_id]);

  return (
    <>
      <div className="landing-page-sec">
        <div className="voicelab-sec">
          <Container>
            <Row className="justify-content-md-center">
              <div className="static-page-sec">
                <div className="voicelab-box">
                  <Row>
                    <Col md={12} xl={12} lg={12}>
                      <div className="static-page-card">
                        {pageData.loading ? (
                          <div className="static-page-content">
                            <Skeleton count={1} height={50} width={200} className="mb-2" />
                            <Skeleton count={1} height={175} />
                          </div>
                        ) : Object.keys(pageData.data).length > 0 &&
                          Object.keys(pageData.data.static_page).length > 0 ? (
                            <div className="static-page-content">
                              <h1 className="text-capitalize">
                                {pageData.data.static_page.title}
                              </h1>
                              <p
                                className="text-capitalize"
                                dangerouslySetInnerHTML={{
                                  __html: pageData.data.static_page.description_formatted,
                                }}
                              ></p>
                            </div>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default StaticPagesIndex;
