import React, { useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LandingFooterIndex from "../layouts/Footer/LandingFooterIndex.jsx";

const VoiceLabEmpty = (props) => {
  return (
    <>
      <div className="voice-clone-no-data-wrapped">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={7}>
              <div className="voice-clone-no-data-frame">
                <div className="voice-no-data-icon-area text-center">
                  <Image
                    className="voice-no-data-image"
                    src={window.location.origin + "/img/empty/no-voice.png"}
                    type="image/svg"
                  />
                </div>
                <div className="voice-no-data-info-area text-center">
                  <h3>No voice has been added to display yet!</h3>
                  <p>Add your first voice, It will take less than a minute </p>
                </div>
                <div className="voice-no-data-action-area">
                  <Button
                    onClick={() => {
                      props.setAddVoiceModal(true);
                    }}
                    className="default-btn"
                  >
                    <span>Add Voice</span>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <LandingFooterIndex />
    </>
  );
};
export default VoiceLabEmpty;
