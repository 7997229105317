import Skeleton from "react-loading-skeleton";

const GenerateLoader = (props) => {
  const data = [0, 1, 2, 3];
  return (
    <>
      <div className="voicelab-list-box">
        {data.map(() => (
          <div className="voicelab-list-card">
            <div className="voicelab-list-info">
              <div class="checkbox">
                <Skeleton height={50} width={150} />
              </div>
              <div className="voicelab-list-created-date-sec">
                <Skeleton height={50} width={200} />
              </div>
            </div>
            <div className="voicelab-list-action-btn-sec">
              <Skeleton height={50} width={70} />
              <Skeleton height={50} width={70} />
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-sec">
        <Skeleton height={50} width={170} />
      </div>
    </>
  );
};

export default GenerateLoader;
