import React, { useEffect,useState } from "react";
import { useDispatch ,useSelector} from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { logoutStart } from '../store/slices/AdminSlice';

const useLogout = () => {
 const dispatch = useDispatch();
 const navigate=useNavigate();
 const logoutData = useSelector((state) => state.admin.logout);
 const [skipRender, setSkipRender] = useState(true);

    const logout = () => {
        dispatch(logoutStart());   
    }
    
    useEffect(() => {
        if (
          !skipRender &&
          !logoutData.loading &&
          Object.keys(logoutData.data).length > 0
        ) {
            navigate("/login")
        }
        setSkipRender(false);
      }, [logoutData]);
    
    return { logout };
}

export default useLogout;