import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CTAIndex = (props) => {
  return (
    <>
      <div className="cta-sec sm-padding">
        <Container>
          <Row>
            <Col md={12}>
                <div className="cta-box">
                    <div className="cta-info">
                        <h3>Ensuring Privacy and Peace of Mind. Safeguarding Your Information is Our Commitment</h3>
                        <p>Our solution prioritizes meeting your security and compliance needs.</p>
                        <div className="cta-btn-sec">
                            <Link to="#" className="default-btn">
                                Let’s Collaborate
                            </Link>
                        </div>
                    </div>
                    <div className="cta-img-sec">
                        <Image
                            className="cta-img"
                            src={
                                window.location.origin + "/img/cta/cta-img.png"
                            }
                            type="image/png"
                        />
                    </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTAIndex;
