import React, { useCallback } from "react";
import { Container, Button, Row, Col, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

const GenerateVoiceUpload = (props) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="generate-voice-wrapped">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="generate-voice-card-area">
                <h3>Generate Voice</h3>
                <p>
                  Unleash the power of our technology to generate realistic,
                  captivating speech.
                </p>
                <Form className="">
                  <div className="generate-voice-full-card-wrapped">
                    <div className="generate-voice-card-label">
                      <p>Choose Voice</p>
                    </div>
                    <div className="generate-voice-change-card">
                      <div className="generate-voice-file-name">
                        <h4>MyVoice.mp3</h4>
                      </div>
                      <div className="generate-voice-change-btn">
                        <Button className="generate-voive-btn">Change</Button>
                      </div>
                    </div>
                  </div>
                  <div className="generate-voice-full-card-wrapped">
                    <div className="generate-voice-card-label">
                      <p>Input</p>
                    </div>
                    <div className="generate-voice-input-wrapped">
                      <div className="generate-voice-input-card">
                        <div class="checkbox">
                          <label
                            class="checkbox-wrapper"
                            for="flexRadioDefault2"
                          >
                            <input
                              type="radio"
                              class="checkbox-input"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                            />
                            <span class="checkbox-title">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 512 512"
                                  fill="#8f59fc"
                                >
                                  <path d="M15 114.235c8.284 0 15-6.716 15-15V30h69.235c8.284 0 15-6.716 15-15s-6.716-15-15-15H15C6.716 0 0 6.716 0 15v84.235c0 8.285 6.716 15 15 15zM497 0h-84.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H482v69.235c0 8.284 6.716 15 15 15s15-6.716 15-15V15c0-8.284-6.716-15-15-15zM497 397.765c-8.284 0-15 6.716-15 15V482h-69.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H497c8.284 0 15-6.716 15-15v-84.235c0-8.285-6.716-15-15-15zM99.235 482H30v-69.235c0-8.284-6.716-15-15-15s-15 6.716-15 15V497c0 8.284 6.716 15 15 15h84.235c8.284 0 15-6.716 15-15s-6.715-15-15-15zM419.66 191.38V96.65c0-4.7-3.81-8.51-8.52-8.51H100.86c-4.71 0-8.52 3.81-8.52 8.51v94.73c0 4.71 3.81 8.52 8.52 8.52h45.24c4.7 0 8.51-3.81 8.51-8.52v-32.45a8.52 8.52 0 018.52-8.52h53.21c4.71 0 8.52 3.81 8.52 8.52v234.14c0 4.71-3.81 8.52-8.52 8.52h-23.27c-4.71 0-8.52 3.81-8.52 8.52v45.24c0 4.7 3.81 8.51 8.52 8.51h125.86c4.71 0 8.52-3.81 8.52-8.51v-45.24c0-4.71-3.81-8.52-8.52-8.52h-23.27c-4.71 0-8.52-3.81-8.52-8.52V158.93c0-4.71 3.81-8.52 8.52-8.52h53.21c4.7 0 8.52 3.81 8.52 8.52v32.45c0 4.71 3.81 8.52 8.51 8.52h45.24c4.71 0 8.52-3.81 8.52-8.52z"></path>
                                </svg>
                              </span>
                              <span class="checkbox-label"> Text</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="generate-voice-input-card">
                        <div class="checkbox">
                          <label
                            class="checkbox-wrapper"
                            for="flexRadioDefault1"
                          >
                            <input
                              type="radio"
                              class="checkbox-input"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked
                            />
                            <span class="checkbox-title">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="#8f59fc"
                                >
                                  <g data-name="01 align center">
                                    <path d="M17.792 7.212A8 8 0 002 9a7.9 7.9 0 00.9 3.671A5.49 5.49 0 005.5 23H12v-2H5.5a3.491 3.491 0 01-.872-6.874l1.437-.371-.883-1.193A5.942 5.942 0 014 9a6 6 0 0111.94-.8l.1.758.757.1A5.99 5.99 0 0118 20.65v2.087a7.989 7.989 0 00-.208-15.525z"></path>
                                    <path d="M18.293 17.707l1.414-1.414L16.414 13a2 2 0 00-2.828 0l-3.293 3.293 1.414 1.414L14 15.414V24h2v-8.586z"></path>
                                  </g>
                                </svg>
                              </span>
                              <span class="checkbox-label">Upload</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="generate-voice-action-btn-sec">
                    <Button
                      className="default-btn"
                      onClick={() => props.setStep(3)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 17 20"
                      >
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          d="M8.5 2v16M2 7v6m13-8v10"
                        ></path>
                      </svg>
                      <span>Genrate Voice</span>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GenerateVoiceUpload;
