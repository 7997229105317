import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container, Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import LandingFooterIndex from "../layouts/Footer/LandingFooterIndex";

const DashboardLoader = () => {
    return (
        <>
          <div className="landing-page-sec">
            <div className="profile-sec">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} lg={12} xl={8}>
                    <div className="profile-box">
                      <h3>My Profile</h3>
                      <p>Clone your own voice or a voice you have a permission.</p>
                      <form className="profile-form-sec">
                            <h5>Basic Info</h5>
                            <div className="profile-form-card">
                                <Skeleton  height={50} width={600}/>
                                <Skeleton height={50} width={150} />
                            </div>
                            <div className="profile-form-card">
                                <Skeleton  height={50} width={600}/>
                                <Skeleton height={50} width={150} />
                            </div>
                      <div className="change-password-sec mt-4">
                         <Skeleton  height={50} width={200}/>      
                      </div>
                      <div className="two-step-auth-sec">
                        <h6>
                          <Skeleton  height={50} width={600}/>
                        </h6>
                      </div>
                      <div className="delete-account-sec">                            
                        <Skeleton  height={50} width={200}/>
                      </div>
                      </form>
                    </div>           
                  </Col>
                </Row>
              </Container>
            </div>
            <LandingFooterIndex />
          </div>        
        </>
      )
}

export default DashboardLoader