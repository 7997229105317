import React, { useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';

const GeneratedVoiceAudio = (props) => {

  const navigate = useNavigate();
  const generatedVoiceStore = useSelector(state => state.generated.generatedVoiceStore);

  return (
    <>
      <div className="generate-voice-wrapped">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="generate-voice-card-area">
                <h3>Generated Voice</h3>
                <p>
                  Unleash the power of our technology to generate realistic,
                  captivating speech.
                </p>
                <div className="generate-voice-full-card-wrapped m-0">
                  <div className="generate-audio-frame">
                    <div className="generate-audio-frame-top">
                      <div className="generate-audio-frame-left">
                        <p>Output File</p>
                      </div>
                      <div className="generate-audio-frame-right">
                        <a className="generate-download-btn" target="blank" href={generatedVoiceStore.data.generated_voice.output}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                            fill="#8f59fc"
                          >
                            <path d="M188.821 377.6c37.49 37.491 98.274 37.491 135.765.001l.001-.001 68.523-68.523c12.712-12.278 13.064-32.536.786-45.248-12.278-12.712-32.536-13.064-45.248-.786-.267.257-.529.52-.786.786l-59.371 59.349L288 32c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32l.448 290.709-58.901-58.901c-12.712-12.278-32.97-11.926-45.248.786-11.977 12.401-11.977 32.061 0 44.462l68.522 68.544z"></path>
                            <path d="M480 309.333c-17.673 0-32 14.327-32 32v97.941a8.746 8.746 0 01-8.725 8.725H72.725A8.746 8.746 0 0164 439.274v-97.941c0-17.673-14.327-32-32-32s-32 14.327-32 32v97.941C.047 479.42 32.58 511.953 72.725 512h366.549c40.146-.047 72.678-32.58 72.725-72.725v-97.941C512 323.66 497.673 309.333 480 309.333z"></path>
                          </svg>
                          Download
                        </a>
                      </div>
                    </div>
                    <div className="generate-audio-frame-middle mt-3">
                      <AudioPlayer
                        autoPlay
                        src={generatedVoiceStore.data.generated_voice.output}
                        onPlay={e => console.log("onPlay")}
                      />
                    </div>
                  </div>
                  <div className="generate-audio-btn">
                    <Button className="download-selected-btn" onClick={()=> navigate("/generate-voice")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 11 14"
                      >
                        <path
                          stroke="url(#paint0_linear_69_3566)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5.5 1.441V12.56M1 4.979V9.148m9-5.56v6.95"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_69_3566"
                            x1="5.5"
                            x2="5.5"
                            y1="1.441"
                            y2="12.559"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#5575FF"></stop>
                            <stop offset="1" stop-color="#8F59FC"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      <span>Genrate More</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GeneratedVoiceAudio;
