import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Dropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";

const LandingPageHeader = (props) => {

  const { logout } = useLogout();
  const navigate = useNavigate();
  const token = localStorage?.getItem("token") || sessionStorage?.getItem("token");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <NavLink to="/">
              <Image
                className="logo"
                src={window.location.origin + "/img/logo.png"}
                type="image/png"
              />
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="hamburger-menu"
                src={window.location.origin + "/img/hamburger-menu.png"}
                type="image/png"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link className="nav-link" onClick={()=> navigate("/")}>Home</Nav.Link>
                <Nav.Link className="nav-link" target="_blank" href="/page/about">About</Nav.Link>
                <Nav.Link className="nav-link" target="_blank" href="/page/contact">Contact Us</Nav.Link>
                <Nav.Link className="nav-link" target="_blank" href="/page/blog">Blog</Nav.Link>
                {!token && (
                  <NavLink className="nav-link" to="/login">Sign In</NavLink>
                )
                }
                {!token && (
                  <NavLink to="/register" className="btn-header nav-link"> Sign Up </NavLink>
                )
                }
                {token && (
                  <div className="user-dropdown-sec">
                    <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
                      <Dropdown.Toggle id="dropdown-basic">
                        <Image
                          className="user-dropdown-img"
                          src={
                            window.location.origin + "/img/user-img.png"
                          }
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/settings" onClick={() => handleDropdownClose()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M34.283 384c17.646 30.626 56.779 41.148 87.405 23.502l.062-.036 9.493-5.483A191.51 191.51 0 00192 437.055V448c0 35.346 28.654 64 64 64s64-28.654 64-64v-10.944a191.494 191.494 0 0060.757-35.115l9.536 5.504c30.633 17.673 69.794 7.167 87.467-23.467 17.673-30.633 7.167-69.794-23.467-87.467l-9.472-5.461a194.15 194.15 0 000-70.187l9.472-5.461c30.633-17.673 41.14-56.833 23.467-87.467-17.673-30.633-56.833-41.14-87.467-23.467l-9.493 5.483A191.518 191.518 0 00320 74.944V64c0-35.346-28.654-64-64-64s-64 28.654-64 64v10.944a191.494 191.494 0 00-60.757 35.115l-9.536-5.525C91.073 86.86 51.913 97.367 34.24 128s-7.167 69.794 23.467 87.467l9.472 5.461a194.15 194.15 0 000 70.187l-9.472 5.461C27.158 314.296 16.686 353.38 34.283 384zM256 170.667c47.128 0 85.333 38.205 85.333 85.333S303.128 341.333 256 341.333 170.667 303.128 170.667 256s38.205-85.333 85.333-85.333z"></path>
                          </svg>
                          <span>Settings</span>
                        </Link>
                        <Link to="/profile" onClick={() => handleDropdownClose()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <g fill="#6e6d8e">
                              <circle cx="256" cy="128" r="128" data-original="#000000"></circle>
                              <path
                                d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                          <span>My Account</span>
                        </Link>
                        <Link to="#" onClick={logout} >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 374 374"
                          >
                            <path
                              fill="#6E6D8E"
                              d="M101.669 235h144v80c0 32.427-26.24 58.667-58.666 58.667h-128C26.576 373.667.336 347.427.336 315V59C.336 26.573 26.576.333 59.003.333h128c32.426 0 58.666 26.24 58.666 58.667v80h-144c-26.453 0-48 21.547-48 48 0 26.454 21.547 48 48 48zm267.307-59.306l-64-64a16.005 16.005 0 10-22.635 22.634l36.694 36.694H101.669a15.998 15.998 0 00-16 16 15.998 15.998 0 0016 16h217.366l-36.694 36.693a16 16 0 0022.614 22.634l64-64a16.007 16.007 0 004.69-11.317 16.013 16.013 0 00-4.69-11.317l.021-.021z"
                            ></path>
                          </svg>
                          <span>Logout</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default LandingPageHeader;
