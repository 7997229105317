import React, { useEffect, useState } from "react";
import { Form, Button, Image, InputGroup, Row, Col } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { registerStart } from "../../store/slices/AdminSlice"
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux"
import OTPVerificationModal from "./OtpVerificationModal.jsx";
import { ButtonLoader } from "../Helper/Loader";
import * as Yup from "yup";
import AuthSection from "./AuthSection.jsx";
import configuration from "react-global-configuration";
import SocialButton from "../Helper/SocialButton.jsx";

const RegisterIndex = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("register");
  const register = useSelector((state) => state.admin.register);
  const [showPassword, setShowPassword] = useState(
  {password:false,
  confirmPassword:false});
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const handleRegister = (values) => {
    dispatch(registerStart(values))
  }

  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name.required"))
      .min(5, t("name.invalid")),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
    .required(t("password.required"))
    .matches(/^\S+$/, t("password.space_invalid"))
    .matches(/[A-Z]/, t("password.invalid"))
    .matches(/[0-9]/, t("password.number_invalid"))
    .matches(/[\W_]/, t("password.special_character_invalid"))
    .min(6, t("password.min_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.invalid")
        ),
      }),
  });

  const togglePasswordVisibility = (value) => {
    if(value==1)
    setShowPassword(prevState => ({
      ...prevState,
      password: !prevState.password
    }));
    else
    setShowPassword(prevState => ({
      ...prevState,
      confirmPassword: !prevState.confirmPassword
    }));
    
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(register.data).length > 0
    ) {
      setShowOTPVerifyModal({
        ...register.data,
        type: "email"
      });
    }
    setSkipRender(false);
  }, [register]);

  // social logins

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <AuthSection/>
          <div className="auth-right-sec">
            <div className="auth-right-box">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={registerSchema}
                    onSubmit={handleRegister}
                  >

                    {({ setFieldValue, values, touched, errors }) => (
                      <FORM className="auth-form-sec">
                        <div className="auth-form-card">
                          <h3>Sign Up</h3>
                          <div className="auth-social-link-box">
                            <div className="auth-social-link-card">
                              {configuration.get("configData.FB_CLIENT_ID") ?
                                <SocialButton
                                  provider="facebook"
                                  appId={configuration.get("configData.FB_CLIENT_ID")}
                                  onLoginSuccess={handleFacebookLogin}
                                  onLoginFailure={handleSocialLoginFailure}
                                  className="auth-social-btn"
                                  id="facebook-connect"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-brand-facebook"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#878E96"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                  </svg>
                                  {t("facebook_signin_heading")}
                                </SocialButton>
                              :null}
                              {configuration.get("configData.GOOGLE_CLIENT_ID") ?
                                <SocialButton
                                  provider="google"
                                  key={"google"}
                                  appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                                  onLoginSuccess={handleGoogleLogin}
                                  onLoginFailure={handleSocialLoginFailure}
                                  className="auth-social-btn"
                                  id="google-connect"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-brand-google"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#878E96"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z" />
                                  </svg>
                                  {t("google_signin_heading")}
                                </SocialButton>
                              :null}
                            </div>
                          </div>
                          {configuration.get("configData.FB_CLIENT_ID") || configuration.get("configData.GOOGLE_CLIENT_ID") ? <div className="divider-separtor-sec">
                            <span>Or with</span>
                          </div>:null}
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-user"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("name.placeholder")}
                              type="text"
                              autoFocus={true}
                              name="name"
                            />
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-mail"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                <path d="M3 7l9 6l9 -6" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("email.placeholder")}
                              type="email"
                              name="email"
                            />
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                          <InputGroup className="mb-3 auth-input-group">
                            <InputGroup.Text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-lock"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              type={showPassword.password ? "text" : "password"}
                              name="password"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="border-right-input"
                              onClick={() => togglePasswordVisibility(1)}
                            >
                              {!showPassword.password ? (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-eye"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                              </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#878E96" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>)

                              }

                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                          <InputGroup className="mb-3 auth-input-group">
                            <InputGroup.Text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-lock"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("password_confirmation.placeholder")}
                              name="password_confirmation"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              type={showPassword.confirmPassword ? "text" : "password"}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="border-right-input"
                              onClick={() => togglePasswordVisibility(0)}
                            >
                              {!showPassword.confirmPassword ? (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-eye"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                              </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#878E96" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>)

                              }
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="errorMsg"
                          />
                          <div className="auth-btn-sec">
                            <Button type="submit" className="default-btn" disabled={register.buttonDisable}>
                              {register.buttonDisable ? (
                                <ButtonLoader varient="black" />
                              ) : (
                                t("heading")
                              )}
                            </Button>
                          </div>
                        </div>
                        <div className="auth-footer-sec">
                          <Link to="/login">{t("login")}</Link>
                        </div>
                        <div className="auth-review-sec">
                          <p>
                            Review
                            <Link to="/page/terms">Terms & Condition</Link>
                            and
                            <Link to="/page/privacy">Privacy Policy</Link>
                          </p>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {showOTPVerifyModal ?
        <OTPVerificationModal OTPVerify={showOTPVerifyModal} closeShowOTPVerify={() => setShowOTPVerifyModal(false)} setShowOTPVerifyModal={setShowOTPVerifyModal} />
        : null}

    </>
  );
};

export default RegisterIndex;
