import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { phraseListSuccess,phraseListFailure } from "../slices/PhraseSlice";


function* phraseListAPI(action) {
  yield getCommonSaga({
    apiUrl: "phrases",
    payload: action.payload,
    successCallback: true,
    successNotify:false,
    success: data => {
      store.dispatch(phraseListSuccess(data));
     
    },
    failure: phraseListFailure,
  });
}


export default function* PhraseSaga() {
  yield all([
    yield takeLatest('phrase/phraseListStart',phraseListAPI),
  ]);
}

