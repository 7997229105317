import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  generatedVoiceListSuccess,
  generatedVoiceListFailure,
  generatedVoiceStoreSuccess,
  generatedVoiceStoreFailure,
  generatedVoiceDeleteSuccess,
  generatedVoiceDeleteFailure,
  generatedVoiceDetailsSuccess,
  generatedVoiceDetailsFailure,
  generatedVoiceStatusSuccess,
  generatedVoiceStatusFailure,
} from "../slices/GeneratedSlice";

function* generatedVoiceListAPI(action) {
  yield getCommonSaga({
    apiUrl: "generated_voices", 
    payload: action.payload,
    successNotify: false,
    success: generatedVoiceListSuccess,
    failure: generatedVoiceListFailure,
  });
}

function* generatedVoiceStoreAPI(action) {
  yield getCommonSaga({
    apiUrl: "generated_voices/store",
    payload: action.payload,
    successNotify: true,
    success: generatedVoiceStoreSuccess,
    failure: generatedVoiceStoreFailure,
  });
}

function* generatedVoiceDeleteAPI(action) {
  const generatedVoiceListData = yield select(state => state.generated.generatedVoiceList.data);
  yield getCommonSaga({
    apiUrl: "generated_voices/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(generatedVoiceDeleteSuccess(data));
      if(Object.keys(generatedVoiceListData).length > 0 && generatedVoiceListData.generated_voices) {
        store.dispatch(generatedVoiceListSuccess({
          ...generatedVoiceListData,
          generated_voices: generatedVoiceListData.generated_voices.filter(item => item.unique_id != action.payload.generated_voice_unique_id),
          total_generated_voices: generatedVoiceListData.total_generated_voices - 1,
        }));
      }
    },
    failure: generatedVoiceDeleteFailure,
  });
}

function* generatedVoiceDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "generated-voice",
    payload: action.payload,
    successCallback: true,
    success: generatedVoiceDetailsSuccess,
    failure: generatedVoiceDetailsFailure,
  });
}

function* generatedVoiceStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "generated-voice",
    payload: action.payload,
    successCallback: true,
    success: generatedVoiceStatusSuccess,
    failure: generatedVoiceStatusFailure,
  });
}

export default function* GeneratedSaga() {
  yield all([yield takeLatest("generated/generatedVoiceListStart", generatedVoiceListAPI)]);
    yield all([yield takeLatest("generated/generatedVoiceStoreStart", generatedVoiceStoreAPI)]);
    yield all([yield takeLatest("generated/generatedVoiceDeleteStart", generatedVoiceDeleteAPI)]);
    yield all([yield takeLatest("generated/generatedVoiceDetailsStart", generatedVoiceDetailsAPI)]);
    yield all([yield takeLatest("generated/generatedVoiceStatusStart", generatedVoiceStatusAPI)]);
}
