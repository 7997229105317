import React, { useState, useEffect } from "react";
import { Form, Button, Image, InputGroup, Row, Col, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { emailVerifyStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { resendOtpStart } from "../../store/slices/AdminSlice";
import { twoStepAuthStart } from "../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup"
import { updateEmailStart } from "../../store/slices/AdminSlice";
import { updateEmailCodeStart } from "../../store/slices/AdminSlice";

const OTPVerificationModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("otp_verify");
  const [skipRender, setSkipRender] = useState(true);
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const updateEmailCode = useSelector(state => state.admin.updateEmailCode);
  const twoStepAuth = useSelector((state) => state.admin.twoStepAuth);
  const resendOtp = useSelector((state) => state.admin.resendOtp);
 

  const handleSubmit = (values) => {
    if (values.otp) {
      if (values.otp.length === 6) {
        if (props.OTPVerify.type == "2fa") {
          dispatch(
            twoStepAuthStart({
              email: props.OTPVerify.email,
              verification_code: values.otp,
            })
          );
        }
        else if(props.OTPVerify.type== "email") {
          dispatch(
            emailVerifyStart({
              email: props.OTPVerify.email,
              verification_code: values.otp,
            })
          );
        }
        else{
          dispatch(
            updateEmailStart({
              email: props.OTPVerify.email,
              verification_code:values.otp,
            })
          );
        }
      } else {
        getErrorNotificationMessage(t("verification_code_length_error"));
      }
    } else {
      getErrorNotificationMessage(t("verification_code_required_error"));
    }
  };

  const otpSchema = Yup.object().shape({
    otp: Yup.string().required(t("required"))
    .min(6, t("six_digits")),
    
  });

  const handleresendOtp = () => {
    if(props.OTPVerify.type== "email"||props.OTPVerify.type== "2fa")
    dispatch(
      resendOtpStart({
        email: props.OTPVerify.email,
        code_type: props.OTPVerify.type
      })
    );
    else{
      props.buttonDisable()
      dispatch(
        updateEmailCodeStart({
          email: props.OTPVerify.email,
        }))

    }

  };

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.email_status == 1) {
        props.closeShowOTPVerify();
        navigate("/")

      }

    }
    if (
      !skipRender &&
      !twoStepAuth.loading &&
      Object.keys(twoStepAuth.data).length > 0
    ) {
      if (twoStepAuth.data.email_status == 1) {
        props.closeShowOTPVerify();
        navigate("/")

      }

    }
    if (
      !skipRender &&
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeShowOTPVerify();

    }
    setSkipRender(false);
  }, [emailVerify, twoStepAuth]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeShowOTPVerify();

    }
    setSkipRender(false);
  }, [updateEmail]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.OTPVerify}
        onHide={props.closeShowOTPVerify}
      >
        <Modal.Body>
          <Button onClick={props.closeShowOTPVerify} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <Formik
            initialValues={{
              otp: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={otpSchema}
          >
            {({ values, setFieldValue }) => (
              <FORM className="auth-form-sec">
                <div className="verification-item">
                  <h4>{t("otp_verification")}</h4>
                  <OtpInput
                    value={values.otp}
                    onChange={(value) => {
                      const numericValue = value.replace(/\D/g, '');
                      setFieldValue('otp', numericValue)
                    }}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <ErrorMessage
                            component={"div"}
                            name="otp"
                            className="errorMsg "
                          />
                <div className="verification-btn-sec">
                  <span>{t("not_getting_code")}</span>
                  <button
                    type="button"
                    onClick={handleresendOtp}
                    className="resend-link"
                    disabled={
                      emailVerify.buttonDisable ||
                      resendOtp.buttonDisable||updateEmailCode.buttonDisable
                    }
                  >
                    {resendOtp.buttonDisable||updateEmailCode.buttonDisable ? (
                      <ButtonLoader />
                    ) : (
                      t("resend_otp")
                    )}
                  </button>
                </div>
                <div className="verify-btn-sec">
                  <Button
                    type="submit"
                    className="default-btn"
                    disabled={
                      emailVerify.buttonDisable ||
                      resendOtp.buttonDisable||twoStepAuth.buttonDisable||updateEmail.buttonDisable
                    }
                  >
                    {emailVerify.buttonDisable ||twoStepAuth.buttonDisable ||updateEmail.buttonDisable? (
                      <ButtonLoader />
                    ) : (
                      t("validate")
                    )}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OTPVerificationModal;
