import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  Accordion,
  Image,
} from "react-bootstrap";
import "toolcool-range-slider/dist/plugins/tcrs-marks.min.js";
import "toolcool-range-slider";
import "toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js";
import { Tooltip } from "react-tooltip";
import { useDropzone } from "react-dropzone";
import ChooseVoiceModal from "./ChooseVoiceModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { generatedVoiceStoreStart } from "../../store/slices/GeneratedSlice";

const GenerateVoiceText = (props) => {
  
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragActive } = useDropzone({
    accept: {
      'text/txt': [".txt"],
      'audio/*': [".mp3", ".wav", ".ogg"],
      'audio/wav': [".wav"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    },
  });

  const dispatch = useDispatch();
  const [selectVoice, setSelectVoice] = useState(false);
  // const [selectedVoice, setSelectedVoice] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [files, setFiles] = useState([]);
  const generatedVoiceStore = useSelector(state => state.generated.generatedVoiceStore);
  const selectedVoice = useSelector(state => state.voice.selectedVoice);
  const formRef = useRef(null);

  const closeSelectVoiceModal = () => {
    setSelectVoice(false);
  }

  const validationSchema = Yup.object().shape({
    voice_id: Yup.string().required("Voice Required"),
    input_type: Yup.string().required("Type Required"),
    input: Yup.string().required("Input Required")
    .matches(/^[a-zA-Z0-9,.& ]*$/, "Invalid")
    .trim()
    .notOneOf([""]),
  });

  const handleSubmit = (values) => {
    let newValues = {
      ...values,
      input: values.input_type == "text" ? values.input : files[0]
    }
    dispatch(generatedVoiceStoreStart(newValues));
  };

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    if (!skipRender && !generatedVoiceStore.loading && Object.keys(generatedVoiceStore.data).length > 0) {
      props.setStep(3);
    }
    setSkipRender(false);
  }, [generatedVoiceStore]);

  useEffect(()=> {
    if(selectedVoice){
      formRef.current.setFieldValue("voice_id", selectedVoice.id);
    }
  }, [selectedVoice])

  return (
    <>
      <div className="generate-voice-wrapped">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="generate-voice-card-area">
                <h3>Generate Voice</h3>
                <p>
                  Unleash the power of our technology to generate realistic,
                  captivating speech.
                </p>
                <Formik
                    initialValues={{
                      voice_id: selectedVoice?.id || "",
                      input_type: "text",
                      input: "",
                      output:"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-9.mp3"
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="">
                      <div className="generate-voice-full-card-wrapped">
                        <div className="generate-voice-card-label">
                          <p>Choose Voice</p>
                        </div>
                        <div className="generate-voice-change-card" onClick={()=> setSelectVoice(true)}>
                          <div className="generate-voice-file-name">
                            <h4>{selectedVoice?.name || "No Voice Selected"}</h4>
                          </div>
                          <div className="generate-voice-change-btn">
                            <Button className="generate-voive-btn">Change</Button>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        component={"div"}
                        name="voice_id"
                        className="errorMsg"
                      />
                      {/* <div className="tt-advance-setting-frame">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Advance Settings </Accordion.Header>
                            <Accordion.Body>
                              <div className="advance-setting-full-body">
                                <div className="advance-setting-cards">
                                  <h5>
                                    Transpose{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      width="100%!important"
                                      marks="true"
                                      marks-count="2"
                                      marks-values-count="2"
                                      min="-12"
                                      max="12"
                                      value="0"
                                      step="1"
                                      round="0"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
                                <div className="advance-setting-cards">
                                  <h5>
                                    Median Filtering{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      data="0, 1, 2, 3, 4, 5"
                                      marks="true"
                                      marks-count="6"
                                      marks-values-count="6"
                                      step="1"
                                      value="0"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
    
                                <div className="advance-setting-cards">
                                  <h5>
                                    Audio Resampling{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      data="0, 1600, 3200, 22045, 4050, 5800"
                                      marks="true"
                                      marks-count="6"
                                      marks-values-count="6"
                                      step="1"
                                      value="0"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
                                <div className="advance-setting-cards">
                                  <h5>
                                    Feature Ratio{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      data="0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1"
                                      marks="true"
                                      marks-count="10"
                                      marks-values-count="10"
                                      step="0.1"
                                      value="0.1"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
                                <div className="advance-setting-cards">
                                  <h5>
                                    Volume Envelope Scaling{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      data="0, 1600, 3200, 45, 4050, 5800"
                                      marks="true"
                                      marks-count="6"
                                      marks-values-count="6"
                                      step="1"
                                      value="0"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
                                <div className="advance-setting-cards">
                                  <h5>
                                    Protect Voiceless Consonants{" "}
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-html=" <h4>Transpose</h4>This parameter controls the transposition of audio, allowing for an adjustment in pitch. It accepts integer values representing the number of semitones to transpose the audio. Positive values raise the pitch by the specified semitone count (e.g., 12 for an octave higher), while negative values lower the pitch (e.g., -12 for an octave lower)."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 512 512"
                                      >
                                        <g data-name="Layer 2">
                                          <g data-name="Layer 1">
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="256"
                                              fill="#818181"
                                              data-original="#252a3f"
                                            ></circle>
                                            <path
                                              fill="#fff"
                                              d="M256.26 173.11a24.63 24.63 0 01-16.67-6q-6.92-6-6.92-16.92a21.07 21.07 0 017.09-16.32 23.87 23.87 0 0116.5-6.41 24.47 24.47 0 0116.06 5.81q7 5.82 7 16.92 0 10.77-6.84 16.84a23.66 23.66 0 01-16.22 6.08zm23.07 48.2v134.84q0 14-6.67 21.19a22.09 22.09 0 01-16.92 7.18 21.1 21.1 0 01-16.66-7.35q-6.41-7.35-6.41-21v-133.5q0-13.83 6.41-20.84t16.66-7a22.31 22.31 0 0116.92 7q6.68 7 6.67 19.48z"
                                              data-name="i"
                                              data-original="#ffffff"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </a>
                                    <Tooltip id="my-tooltip" />
                                  </h5>
                                  <div className="advance-setting-range-slider">
                                    <tc-range-slider
                                      data="0, 1, 2, 3, 4, 5"
                                      marks="true"
                                      marks-count="6"
                                      marks-values-count="6"
                                      step="1"
                                      value="0"
                                      generate-labels="true"
                                      moving-tooltip="true"
                                      moving-tooltip-distance-to-pointer="40"
                                      moving-tooltip-width="100%"
                                      moving-tooltip-height="30"
                                      moving-tooltip-bg="#000"
                                      moving-tooltip-text-color="#fff"
                                      pointer-width="24px"
                                      pointer-height="24px"
                                      pointer-radius="100%"
                                    ></tc-range-slider>
                                  </div>
                                </div>
                                <div className="advance-setting-cards">
                                  <Button className="advance-set-btn">Reset</Button>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div> */}
                      <div className="generate-voice-full-card-wrapped">
                        <div className="generate-voice-card-label">
                          <p>Input</p>
                        </div>
                        <div className="generate-voice-input-wrapped">
                          <div className="generate-voice-input-card">
                            <div class="checkbox">
                              <label
                                class="checkbox-wrapper"
                                for="flexRadioDefault2"
                              >
                                <input
                                  type="radio"
                                  class="checkbox-input"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  onClick={()=> setFieldValue("input_type", "text")}
                                  checked={values.input_type == "text"}
                                />
                                <span class="checkbox-title">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 512 512"
                                      fill="#8f59fc"
                                    >
                                      <path d="M15 114.235c8.284 0 15-6.716 15-15V30h69.235c8.284 0 15-6.716 15-15s-6.716-15-15-15H15C6.716 0 0 6.716 0 15v84.235c0 8.285 6.716 15 15 15zM497 0h-84.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H482v69.235c0 8.284 6.716 15 15 15s15-6.716 15-15V15c0-8.284-6.716-15-15-15zM497 397.765c-8.284 0-15 6.716-15 15V482h-69.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H497c8.284 0 15-6.716 15-15v-84.235c0-8.285-6.716-15-15-15zM99.235 482H30v-69.235c0-8.284-6.716-15-15-15s-15 6.716-15 15V497c0 8.284 6.716 15 15 15h84.235c8.284 0 15-6.716 15-15s-6.715-15-15-15zM419.66 191.38V96.65c0-4.7-3.81-8.51-8.52-8.51H100.86c-4.71 0-8.52 3.81-8.52 8.51v94.73c0 4.71 3.81 8.52 8.52 8.52h45.24c4.7 0 8.51-3.81 8.51-8.52v-32.45a8.52 8.52 0 018.52-8.52h53.21c4.71 0 8.52 3.81 8.52 8.52v234.14c0 4.71-3.81 8.52-8.52 8.52h-23.27c-4.71 0-8.52 3.81-8.52 8.52v45.24c0 4.7 3.81 8.51 8.52 8.51h125.86c4.71 0 8.52-3.81 8.52-8.51v-45.24c0-4.71-3.81-8.52-8.52-8.52h-23.27c-4.71 0-8.52-3.81-8.52-8.52V158.93c0-4.71 3.81-8.52 8.52-8.52h53.21c4.7 0 8.52 3.81 8.52 8.52v32.45c0 4.71 3.81 8.52 8.51 8.52h45.24c4.71 0 8.52-3.81 8.52-8.52z"></path>
                                    </svg>
                                  </span>
                                  <span class="checkbox-label"> Text</span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="generate-voice-input-card">
                            <div class="checkbox">
                              <label
                                class="checkbox-wrapper"
                                for="flexRadioDefault1"
                              >
                                <input
                                  type="radio"
                                  class="checkbox-input"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  onClick={()=> setFieldValue("input_type", "file")}
                                  checked={values.input_type == "file"}
                                />
                                <span class="checkbox-title">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="#8f59fc"
                                    >
                                      <g data-name="01 align center">
                                        <path d="M17.792 7.212A8 8 0 002 9a7.9 7.9 0 00.9 3.671A5.49 5.49 0 005.5 23H12v-2H5.5a3.491 3.491 0 01-.872-6.874l1.437-.371-.883-1.193A5.942 5.942 0 014 9a6 6 0 0111.94-.8l.1.758.757.1A5.99 5.99 0 0118 20.65v2.087a7.989 7.989 0 00-.208-15.525z"></path>
                                        <path d="M18.293 17.707l1.414-1.414L16.414 13a2 2 0 00-2.828 0l-3.293 3.293 1.414 1.414L14 15.414V24h2v-8.586z"></path>
                                      </g>
                                    </svg>
                                  </span>
                                  <span class="checkbox-label">Upload</span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="input_type"
                          className="errorMsg"
                        />
                      </div>
                      {values.input_type == "text" ? 
                      <div className="generate-voice-full-card-wrapped">
                        <div className="generate-voice-card-label">
                          <p>Text</p>
                        </div>
                        <div className="generate-voice-textarea-card">
                          <Field
                            className="form-control"
                            as="textarea"
                            rows={4}
                            placeholder="Type or paste text to convert"
                            name="input"
                          />
                        </div>
                      </div> :
                      <div className="generate-voice-full-card-wrapped">
                        {files.length <= 0 && <div className="generate-voice-file-upload-frame" {...getRootProps({
                          onDrop: (e) => {
                            let ext = e.dataTransfer.files[0].name.slice(
                              e.dataTransfer.files[0].name.lastIndexOf(".")
                            );
                            if(e.dataTransfer.files.length == 1 && getInputProps().accept.includes(ext) && e.dataTransfer.files[0].type)
                              {
                                setFieldValue("input", e.dataTransfer.files[0].size);
                              }
                            else if (!getInputProps().accept.includes(ext))
                              getErrorNotificationMessage("Invalid File");
                            else
                              getErrorNotificationMessage("Select one file only");
                          },
                          onChange: (e) => {
                            let ext = e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".")));
                            if (e.target.files.length == 1 && getInputProps().accept.includes(ext))
                              setFieldValue("input", e.target.files[0].size)
                            else if (!getInputProps().accept.includes(ext))
                              getErrorNotificationMessage("Invalid File");
                            else
                              getErrorNotificationMessage("Select one file only");
                          },
                        })}>
                          <input {...getInputProps()} />
                          <div className="generate-voice-upload-icon">
                            <Image
                              className="upload-icons"
                              src={window.location.origin + "/img/icons/upload.svg"}
                              type="image/png"
                            />
                          </div>
                          <div className="generate-voice-upload-info">
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <p>
                                Drag 'n' drop some files here, or click to select
                                files
                              </p>
                            )}
                          </div>
                        </div>}
                        {files &&
                          files.map((file, index) => (
                            <div className="generate-voice-file-preview" key={index}>
                              <div className="generate-voice-file-preview-left">
                                <div className="generate-preview-icons">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    fill="#8f59fc"
                                  >
                                    <path d="M14 7V.46a6.977 6.977 0 012.465 1.59l3.484 3.486A6.954 6.954 0 0121.54 8H15a1 1 0 01-1-1zm8 3.485V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515c.163 0 .324.013.485.024V7a3 3 0 003 3h6.976c.011.161.024.322.024.485zM14 19a1 1 0 00-1-1H8a1 1 0 000 2h5a1 1 0 001-1zm3-4a1 1 0 00-1-1H8a1 1 0 000 2h8a1 1 0 001-1z"></path>
                                  </svg>
                                </div>
                                <div className="generate-preview-info">
                                  <p>{file.name}</p>
                                  <span>{(file.size/(1024*1024)).toFixed(2)}MB</span>
                                </div>
                              </div>
                              <div className="generate-voice-file-preview-left">
                                <div className="generate-preview-cancel">
                                  <Button className="cancel-file-btn" onClick={()=> {
                                    removeFile(index)
                                    setFieldValue("input", "")
                                  }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="#ff4a4a"
                                        d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>}
                      <ErrorMessage
                        component={"div"}
                        name="input"
                        className="errorMsg"
                      />
                      <div className="generate-voice-action-btn-sec">
                        <Button
                          className="default-btn"
                          disabled={generatedVoiceStore.buttonDisable}
                          type="submit"
                        >
                          {generatedVoiceStore.buttonDisable ? <ButtonLoader/> : <React.Fragment>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 17 20"
                          >
                            <path
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="3"
                              d="M8.5 2v16M2 7v6m13-8v10"
                            ></path>
                          </svg>
                          <span>Genrate Voice</span></React.Fragment>}
                        </Button>
                      </div>
                    </FORM>
                    )}
                    </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {selectVoice && (
        <ChooseVoiceModal
          selectVoice={selectVoice}
          setSelectVoice={setSelectVoice}
          closeSelectVoiceModal={closeSelectVoiceModal}
        />
      )}
    </>
  );
};

export default GenerateVoiceText;
