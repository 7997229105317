import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CreateAudioFileIndex = (props) => {

  return (
    <>
      <div className="create-audio-file-sec sm-padding">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8} lg={9} className="text-center">
                        <div className="section-title">
                            <h2>Create audio files for your commercial use</h2>
                            <p>Spooky allows you to redistribute your generated audio files even after your subscription expires.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="create-audio-file-box">
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/youtube.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Youtube Videos</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/application.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Applications</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/broadcasting.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Broadcasting</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/social-media.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Social Media</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/podcast.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Podcast</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/call-center.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Call Center's</h4>
                            </div>
                            <div className="create-audio-file-card">
                                <Image
                                    className="create-audio-file-icon"
                                    src={
                                        window.location.origin + "/img/create-audio/sales.png"
                                    }
                                    type="image/png"
                                />
                                <h4>Sales</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
      </div>
    </>
  );
};

export default CreateAudioFileIndex;
