import axios from "axios";

export const apiUrl = `https://cms-voxsync.botfingers.com/api/user/`;

const Environment = {
    postMethod: async (action, object, baseUrl = apiUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();
        // append your data
        for (var key in object)
            formData.append(key, object[key]);

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, formData, config);
    },
};

export default Environment;
