import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Switch from "react-js-switch";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { profileStart } from "../../store/slices/AdminSlice";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import DashboardLoader from "../Helper/ProfileLoader";
import { updateEmailCodeStart } from "../../store/slices/AdminSlice";
import OTPVerificationModal from "../Auth/OtpVerificationModal";
import TwoFactorOnModal from "./TwoFactorOnModal";
import LandingFooterIndex from "../layouts/Footer/LandingFooterIndex";

const ProfileIndex = (props) => {
  const t = useTranslation("profile");
  const formRef = useRef();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const updateProfile = useSelector(state => state.admin.updateProfile);
  const updateEmail = useSelector(state => state.admin.updateEmailCode);
  const [isSwitchOn, setIsSwitchOn] = useState();
  const [skipRender, setSkipRender] = useState(true);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [twoFactorActivate, setTwoFactorActivate] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [updateEmailButtonState, setupdateEmailButtonState] = useState(false);


  const switch_onChange_handle = () => {
    setIsSwitchOn(!isSwitchOn);
    setTwoFactorActivate(true)
  };

  const closeChangePasswordModal = () => {
     setChangePassword(false);
  };

  const closeDeleteAccountModal = () => {
    setDeleteAccount(false);
  };
  const closeTwoFactorOnModal = () => {
    setTwoFactorActivate(false);
    setIsSwitchOn(!isSwitchOn);
  };

  const nameSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name.required"))
      .min(5, t("name.invalid")),
  });

  const emailSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  const handleNameUpdate = (values) => {
    dispatch(updateProfileStart(values))
  }

  const handleEmailUpdate = (values) => {
    setupdateEmailButtonState(true)
    dispatch(updateEmailCodeStart(values))
  }

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
    if (
      !skipRender &&
      !updateEmail.loading &&updateEmailButtonState&&
      Object.keys(updateEmail.data).length > 0
    ) {
      setupdateEmailButtonState(false)
      setShowOTPVerifyModal({
        email: formRef.current.values.email,
        type: "updateEmail"
      });
    }
    setSkipRender(false);
    setIsSwitchOn(profile.data.tfa_status == 1 ? true : false)
  }, [updateEmail, profile]);

  return (
    profile.loading ? (<DashboardLoader />) :
      Object.keys(profile.data).length > 0 ? (
        <>
          <div className="landing-page-sec">
            <div className="profile-sec">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} lg={12} xl={8}>
                    <div className="profile-box">
                      <h3>{t("heading")}</h3>
                      <p>{t("message")}</p>
                      <Formik
                        initialValues={{
                          name: profile.data.name,
                          email: profile.data.email,
                        }}
                        validationSchema={nameSchema}
                        onSubmit={handleNameUpdate}
                      >
                        {({ setFieldValue, values }) => (
                          <FORM className="profile-form-sec">
                            <h5>{t("basic_info")}</h5>
                            <div className="profile-form-card">
                              <InputGroup className="mb-0">
                                <InputGroup.Text id="basic-addon1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-user"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#878E96"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder={t("name.placeholder")}
                                  type="text"
                                  name="name"
                                />
                              </InputGroup>
                              <Button className="default-outline-btn" type="submit" disabled={updateProfile.buttonDisable}>
                                {updateProfile.buttonDisable ? (
                                  <ButtonLoader varient="black" />
                                ) : (
                                  t("update_name")
                                )}
                              </Button>
                            </div>
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="errorMsg"
                            />
                          </FORM>
                        )}
                      </Formik>
                      <Formik
                        initialValues={{
                          email: profile?.data.email,
                        }}
                        validationSchema={emailSchema}
                        onSubmit={handleEmailUpdate}
                        innerRef={formRef}
                      >
                        {({ setFieldValue, values }) => (
                          <FORM className="profile-form-sec mt-0">
                            <div className="profile-form-card">
                              <InputGroup className="mb-0">
                                <InputGroup.Text id="basic-addon1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-mail"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#878E96"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                    <path d="M3 7l9 6l9 -6" />
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder={t("email.placeholder")}
                                  type="email"
                                  name="email"
                                />
                              </InputGroup>
                              <Button className="default-outline-btn" type="submit" disabled={updateEmail.buttonDisable && updateEmailButtonState}>
                                {updateEmail.buttonDisable && updateEmailButtonState ? (
                                  <ButtonLoader varient="black" />
                                ) : (
                                  t("update_email")
                                )}
                              </Button>
                            </div>
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                          </FORM>
                        )}
                      </Formik>
                      <div className="change-password-sec mt-4">
                        <h5>{t("security")}</h5>
                        <h6>{t("password_settings")}</h6>
                        <Button
                          className="default-outline-btn "
                          onClick={() => setChangePassword(true)}
                        >
                          {t("change_password")}
                        </Button>
                      </div>
                      <div className="two-step-auth-sec d-flex justify-content-between align-items-center mt-4">
                        <h6>{t("two_factor")}</h6>
                        <div>
                          <Switch
                            value={isSwitchOn}
                            backgroundColor={{ on: "#65C174", off: "grey" }}
                            borderColor={{ on: "#65C174", off: "#e6e6e6" }}
                            onChange={switch_onChange_handle}
                          />
                        </div>
                      </div>
                      <div className="delete-account-sec mt-2">
                        <h5>{t("delete_account")}</h5>
                        <Button
                          className="delete-btn"
                          onClick={() => setDeleteAccount(true)}
                        >
                          {t("delete_account")}
                        </Button>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <LandingFooterIndex />
          </div>
          <ChangePasswordModal
            changePassword={changePassword}
            closeChangePasswordModal={closeChangePasswordModal}
            setChangePassword={setChangePassword}
          />
          <DeleteAccountModal
            deleteAccount={deleteAccount}
            closeDeleteAccountModal={closeDeleteAccountModal}
            setDeleteAccount={setDeleteAccount}
          />
          <TwoFactorOnModal
            twoFactorActivate={twoFactorActivate}
            closeTwoFactorOnModal={closeTwoFactorOnModal}
            setTwoFactorActivate={setTwoFactorActivate}
          />
          {showOTPVerifyModal ?
            <OTPVerificationModal 
            OTPVerify={showOTPVerifyModal} 
            closeShowOTPVerify={() => setShowOTPVerifyModal(false)} 
            setShowOTPVerifyModal={setShowOTPVerifyModal} 
            buttonDisable={() => setupdateEmailButtonState(false)}   />
            : null}
        </>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(profileStart())}
        />
      )
  )
};

export default ProfileIndex;
