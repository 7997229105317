

import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import Skeleton from 'react-loading-skeleton'


const VoiceLoader = () => {
  const data = [0,1,2,3]

  return (
    <>
      <div className="landing-page-sec">
        <div className="voicelab-sec">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={8}>
                <div className="voicelab-box">
                  <Skeleton height={40} width={130} />
                  <Skeleton height={20} width={500} />
                  <div className="voicelab-add-btn-sec">
                    <Skeleton height={70} width={790} />
                  </div>
                  <div className="voicelab-list-box">
                  {data.map(
                          () => (
                    <div className="voicelab-list-card">
                      <div className="voicelab-list-info">
                        <Skeleton height={50} width={100} />
                      </div>
                      <div className="voicelab-list-action-btn-sec">
                        <span></span>
                        <Skeleton className="voicelab-list-action-btn" height={50} width={60} />
                        <Skeleton className="voicelab-list-action-btn" height={50} width={60} />
                        <Skeleton className="voicelab-list-action-btn" height={50} width={60} />
                        <Skeleton className="voicelab-list-action-btn" height={50} width={60} />
                      </div>
                    </div>
                          ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
};

export default VoiceLoader;