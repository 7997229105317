import AdminSlice from "./AdminSlice";
import CommonSlice from "./CommonSlice";
import VoiceSlice from "./VoiceSlice";
import PhraseSlice from "./PhraseSlice";
import GeneratedSlice from "./GeneratedSlice";

export const reducers = {
  common: CommonSlice,
  admin: AdminSlice,
  voice:VoiceSlice,
  phrase:PhraseSlice,
  generated:GeneratedSlice,
};
