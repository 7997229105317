import React, { useState } from "react";
import { Container, Button, Row, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ContactUsIndex = (props) => {

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    // console.log(values)
  }

  return (
    <>
      <div className="contact-us-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>
                  Have a question or just want to say hi?We'd love to hear from
                  you.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={10} xl={6}>
              <div className="contact-us-form-box">
                <h3>Get in Touch with us!</h3>
                <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="contact-us-form">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Name</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter your name"}
                            type="text"
                            name="name"
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="name"
                          className="errorMsg"
                        />
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter your email"}
                            type="email"
                            name="email"
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                        />
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone Number</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter your phone number"}
                            type="text"
                            name="phone"
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="phone"
                          className="errorMsg"
                        />
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Message</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter your message"}
                            as="textarea"
                            rows={3}
                            name="message"
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="message"
                          className="errorMsg"
                        />
                        <div className="contact-us-submit-btn-sec">
                          <Button type="submit" className="default-btn">Submit</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUsIndex;
