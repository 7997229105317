import "./App.css";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";

//css files
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-h5-audio-player/lib/styles.css';


function App() {
  return (
    <Provider store={store}>
      <Base />
    </Provider>
  );
}

export default App;
