import React, { useState } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HomeBannerIndex = (props) => {

  const profile = useSelector((state) => state.admin.profile);


  return (
    <>
      <div className="home-banner-sec">
        <div className="banner-vertical-content">
          <Container>
            <Row>
              <Col md={12} xl={6} className="resp-mrg-btm-md">
                <div className="banner-content">
                  <h1>Text to Speechwith (human-like)AI voices</h1>
                  <p>
                    Generate lifelike speech in any language and voice with free
                    Speech Synthesis and AI Voice software online.
                  </p>
                  <div className="banner-btn-sec">
                    <Link to={Object.keys(profile.data).length > 0 ? "/generated-voice-list" : "/login"} className="default-btn">
                      <span>Generate Now</span>
                    </Link>
                    <Link to="#" className="play-video-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 14 16"
                      >
                        <path
                          fill="url(#paint0_linear_31_1233)"
                          d="M13.5 7.134a1 1 0 010 1.732l-12 6.928a1 1 0 01-1.5-.866V1.072A1 1 0 011.5.206l12 6.928z"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_31_1233"
                            x1="15"
                            x2="-5"
                            y1="8"
                            y2="8"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#5575FF"></stop>
                            <stop offset="1" stopColor="#8F59FC"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={12} xl={6}>
                <div className="banner-img-sec">
                  <Image
                    className="banner-img"
                    src={window.location.origin + "/img/banner-img.svg"}
                    type="image/svg"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomeBannerIndex;
