import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AboutUsIndex = (props) => {

  const profile = useSelector((state) => state.admin.profile);

  return (
    <>
      <div className="about-us-sec sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={6} className="resp-mrg-btm-md">
              <div className="about-content">
                <h2>Text to Speech with the power of AI</h2>
                <p>
                  Our tools use the latest, cutting-edge research to deliver the
                  highest-quality AI speech capabilities.
                </p>
                <div className="about-us-box">
                  <div className="about-us-card">
                    <div className="about-us-icon-sec">
                      <Image
                        className="about-us-icon"
                        src={
                          window.location.origin + "/img/about/about-icon-1.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="about-us-info">
                      <h4>Speed Up Content Creation Journey</h4>
                      <p>
                        Efficiently streamline your content production by
                        effortlessly obtaining precise voiceovers in multiple
                        languages, saving you valuable time, resources, and
                        costs.
                      </p>
                    </div>
                  </div>
                  <div className="about-us-card">
                    <div className="about-us-icon-sec">
                      <Image
                        className="about-us-icon"
                        src={
                          window.location.origin + "/img/about/about-icon-2.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="about-us-info">
                      <h4>Refine and Fine-Tune</h4>
                      <p>
                        Gain precise control over the characteristics, pace and
                        tone of your generated audio.
                      </p>
                    </div>
                  </div>
                  <div className="about-us-card">
                    <div className="about-us-icon-sec">
                      <Image
                        className="about-us-icon"
                        src={
                          window.location.origin + "/img/about/about-icon-3.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="about-us-info">
                      <h4>Get Human-Like, Ultra-Realistic Voices</h4>
                      <p>
                        AI-powered, engaging voices with intonation, tones, and
                        accents that sound just like humans.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="about-us-btn-sec">
                  <Link
                    to={
                      Object.keys(profile.data).length > 0
                        ? "/generated-voice-list"
                        : "/login"
                    }
                    className="default-btn"
                    data-text="Generate Now"
                  >
                    <span>Generate Now</span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="about-img-sec">
                <Image
                  className="about-img"
                  src={window.location.origin + "/img/about/about-img.png"}
                  type="image/png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUsIndex;
