import { all, fork } from "redux-saga/effects";
import AdminSaga from "./AdminSaga";
import VoiceSaga from "./VoiceSaga";
import PhraseSaga from "./PhraseSaga";
import GeneratedSaga from "./GeneratedSaga";
import CommonSaga from "./CommonSaga";

export default function* rootSaga() {
  yield all([
    fork(AdminSaga),
    fork(VoiceSaga),
    fork(PhraseSaga),
    fork(GeneratedSaga),
    fork(CommonSaga),
  ]);
}
