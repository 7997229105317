import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal, InputGroup, Image } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { voiceShowStart, voiceStoreStart } from "../../store/slices/VoiceSlice";
import { v4 as uuidv4 } from 'uuid';
import { ButtonLoader } from "../Helper/Loader";
import { recordVoiceCreateStart } from "../../store/slices/VoiceSlice";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { phraseListStart } from "../../store/slices/PhraseSlice";


const EditVoiceModal = (props) => {

  const t = useTranslation("add_voice");
  const dispatch = useDispatch();
  const recordVoice = useSelector((state) => state.voice.recordVoice);
  const phraseList = useSelector((state) => state.phrase.phraseList);
  const voiceStore = useSelector((state) => state.voice.voiceStore);
  const voiceShow = useSelector((state) => state.voice.voiceShow);
  const [step, setStep] = useState(1);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [voiceId, setVoice_Id] = useState(uuidv4());
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    setStep(4);
    dispatch(voiceShowStart({
        voice_unique_id: props.addVoiceModal,
    }))
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !recordVoice.loading &&
      Object.keys(recordVoice.data).length > 0
    )
      if (currentPhraseIndex < phraseList.data.total_phrases - 1) {
        setCurrentPhraseIndex(currentPhraseIndex + 1);
        setStep(1);
      } else {
        setStep(4);
      }
    if (currentPhraseIndex == phraseList.data.total_phrases) {
      setStep(4);
    }

    setSkipRender(false);
  }, [recordVoice]);


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("required"))
      .min(5, t("invalid")),
  });

  const handleAddVoice = (values) => {
    dispatch(voiceStoreStart({ ...values, voice_unique_id: props.addVoiceModal }))
  }

  useEffect(() => {
    if (
      !skipRender &&
      !voiceStore.loading &&
      Object.keys(voiceStore.data).length > 0
    ) {
      props.closeAddVoiceModal();
    }
    setSkipRender(false);
  }, [voiceStore]);


  return (
    <>
      <Modal
        className="modal-dialog-center add-voice-modal"
        size="md"
        centered
        show={props.addVoiceModal}
        onHide={props.closeAddVoiceModal}
        backdrop="static"
      >
        <Modal.Body>
          <h4>{t("edit_title")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddVoiceModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <>
          {voiceShow.loading ?
            <div className="step-1">
              <Skeleton height={20} width={150} />
              <Skeleton height={40} className="mb-3" />
              <Skeleton height={20} width={150} />
              <Skeleton height={60} className="mb-3" />
              <Skeleton height={50} />
            </div>
          : ((Object.keys(voiceShow.data).length > 0) && Object.keys(voiceShow.data.voice).length > 0) ? <React.Fragment>
            {step == 4 && (
              <div className="step-4">
                <div className="add-voice-sec">
                  <Formik
                    initialValues={{
                      name: voiceShow.data.voice.name,
                      description: voiceShow.data.voice.description,
                      ai_voice_id: voiceShow.data.voice.ai_voice_id,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleAddVoice}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="add-voice-form-sec">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("name")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter name"}
                            type="text"
                            name="name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </Form.Group>
                        <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t("description")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder="How would you describe the voice?"
                            as="textarea"
                            name="description"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </Form.Group>
                        <div className="add-voice-form-btn-sec">
                          <Button className="default-btn" type="submit" disabled={voiceStore.buttonDisable}>
                            {voiceStore.buttonDisable ? (
                              <ButtonLoader varient="black" />
                            ) : (
                              <span> {t("update")}</span>
                            )}
                          </Button>
                        </div>
                      </FORM>
                    )}</Formik>
                </div>
              </div>
            )}
          </React.Fragment> : <SomethingWentWrong handleClick={()=> dispatch(phraseListStart())} />}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditVoiceModal;