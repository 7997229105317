import React, { useState, useEffect } from "react";
import GenerateVoiceText from "./GenerateVoiceText.jsx";
import GenerateVoiceUpload from "./GenerateVoiceUpload.jsx";
import GeneratedVoiceAudio from "./GenerateVoiceAudio.jsx";
import LandingFooterIndex from "../layouts/Footer/LandingFooterIndex.jsx";

const GenerateVoice = (props) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, []);
  
  return (
    <>
      <div className="landing-page-sec">
        {step == 1 && <GenerateVoiceText setStep={setStep} />}
        {step == 2 && <GenerateVoiceUpload setStep={setStep} />}
        {step == 3 && <GeneratedVoiceAudio setStep={setStep} />}
        <LandingFooterIndex />
      </div>
    </>
  );
};

export default GenerateVoice;
