import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const LandingFooterIndex = (props) => {
  return (
    <>
      <footer className="footer-sec">
        <Container>
          <div className="footer-box">
            <Row className="justify-content-between">
              <Col md={6} xl={4} lg={4} className="resp-mrg-btm-md">
                <div className="footer-about-sec">
                  <Image
                    className="footer-logo"
                    src={
                      window.location.origin + "/img/white-logo.png"
                    }
                    type="image/png"
                  />
                  <p>
                  This is a big one and i consider it one of the most important things for a business.
                  </p>
                </div>
              </Col>
              <Col md={3} xl={2} lg={2} className="resp-mrg-btm-md">
                <div className="footer-link-card">
                  <h3>Quick Links</h3>
                  <ul className=" footer-link-list list-unstyled">
                    <li>
                      <a target="_blank" href="/">Home</a>
                    </li>
                    <li>
                      <a target="_blank" href="/page/about">About</a>
                    </li>
                    <li>
                      <a target="_blank" href="/page/blog">Blogs</a>
                    </li>
                    <li>
                      <a target="_blank" href="/page/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} xl={2} lg={2} className="resp-mrg-btm-md">
                <div className="footer-link-card">
                  <h3>Company</h3>
                  <ul className=" footer-link-list list-unstyled">
                    <li>
                      <a target="_blank" href="/page/privacy">Fair play policy</a>
                    </li>
                    <li>
                      <a target="_blank" href="/page/privacy">Privacy Policy</a>
                    </li>
                    <li>
                      <a target="_blank" href="/page/terms">Terms of Services</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xl={3} lg={3}>
                <div className="footer-social-link-sec">
                  <h3>Contact Info</h3>
                  <ul className="list-unstyled contact-us-address-list">
                    <li>
                      <a href="tel:+1-9893752330">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 00-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 00.043-1.391L6.859 3.513a1 1 0 00-1.391-.087l-2.17 1.861a1 1 0 00-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 00.648-.291l1.86-2.171a1 1 0 00-.086-1.391l-4.064-3.696z"
                          ></path>
                        </svg>
                        <span>+1-9893752330</span>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@haive.tech">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M22 4H2v16h20V4zm-2 4l-8 5-8-5V6l8 5 8-5v2z"
                          ></path>
                        </svg>
                        <span>info@haive.tech</span>
                      </a>
                    </li>
                    <li>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M12 1.5a8.26 8.26 0 00-8.25 8.25 8.167 8.167 0 001.662 4.95s.225.296.262.339L12 22.5l6.33-7.465.258-.335v-.002A8.164 8.164 0 0020.25 9.75 8.26 8.26 0 0012 1.5zm0 11.25a3 3 0 110-5.999 3 3 0 010 5.999z"
                          ></path>
                        </svg>
                        <span>No. 2 Venture Drive, #09-24Vision Exchange, Singapore 608526</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
      <div className="copyrights-sec">
        <Container>
          <div className="copyright-card">
           <ul className="footer-social-link-list list-unstyled">
              <li>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="feather feather-facebook"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="feather feather-instagram"
                    viewBox="0 0 24 24"
                  >
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z"></path>
                    <path d="M17.5 6.5L17.51 6.5"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="feather feather-linkedin"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6z"></path>
                    <path d="M2 9H6V21H2z"></path>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </Link>
              </li>
           </ul>
            <p>Copyright © 2023 Rummy - All Rights Reserved.</p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LandingFooterIndex;
