import React, { useEffect, useState } from "react";
import { Form, Button, Image, InputGroup, Row, Col } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link,useNavigate } from "react-router-dom";
import { resetPasswordStart } from "../../store/slices/AdminSlice"
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux"
import OTPVerificationModal from "./OtpVerificationModal.jsx";
import { ButtonLoader } from "../Helper/Loader";
import * as Yup from "yup";
import AuthSection from "./AuthSection.jsx";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const t = useTranslation("reset_password");
  const navigate = useNavigate();
  const resetPassword = useSelector((state) => state.admin.resetPassword);
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword)
  const [showPassword, setShowPassword] = useState(
  {password:false,
  confirmPassword:false});
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    dispatch(resetPasswordStart({...values,reset_token:verifyForgotPassword.data.token}))
  }

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
    .required(t("password.required"))
    .matches(/^\S+$/, t("password.space_invalid"))
    .matches(/[A-Z]/, t("password.invalid"))
    .matches(/[0-9]/, t("password.number_invalid"))
    .matches(/[\W_]/, t("password.special_character_invalid"))
    .min(6, t("password.min_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.invalid")
        ),
      }),
  });

  const togglePasswordVisibility = (value) => {
    if(value==1)
    setShowPassword(prevState => ({
      ...prevState,
      password: !prevState.password
    }));
    else
    setShowPassword(prevState => ({
      ...prevState,
      confirmPassword: !prevState.confirmPassword
    }));
    
  };

  useEffect(() => {
    if (
      !skipRender &&
      !resetPassword.loading &&
      Object.keys(resetPassword.data).length > 0
    ) {
      navigate("/login")
    }
    setSkipRender(false);
  }, [resetPassword]);

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <AuthSection/>
          <div className="auth-right-sec">
            <div className="auth-right-box">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <Formik
                    initialValues={{
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={handleSubmit}
                  >

                    {({ setFieldValue, values, touched, errors }) => (
                      <FORM className="auth-form-sec">
                        <div className="auth-form-card">
                          <h3>{t("heading")}</h3>
                          <InputGroup className="mb-3 auth-input-group">
                            <InputGroup.Text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-lock"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("new_password.placeholder")}
                              type={showPassword.password ? "text" : "password"}
                              name="password"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="border-right-input"
                              onClick={() => togglePasswordVisibility(1)}
                            >
                              {!showPassword.password ? (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-eye"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                              </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#878E96" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>)

                              }
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                          <InputGroup className="mb-3 auth-input-group">
                            <InputGroup.Text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-lock"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                              </svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("confirm_password.placeholder")}
                              name="password_confirmation"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              type={showPassword.confirmPassword ? "text" : "password"}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="border-right-input"
                              onClick={() => togglePasswordVisibility(0)}
                            >
                              {!showPassword.confirmPassword ? (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-eye"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#878E96"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                              </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#878E96" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>)
                              }
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="errorMsg"
                          />
                          <div className="auth-btn-sec">
                            <Button type="submit" className="default-btn" disabled={resetPassword.buttonDisable}>
                              {resetPassword.buttonDisable ? (
                                <ButtonLoader varient="black" />
                              ) : (
                                t("save_btn.text")
                              )}
                            </Button>
                          </div>
                          <div className="auth-footer-sec">
                            <Link to="/login">
                              {t("cancel")}
                            </Link>
                          </div>
                        </div> 
                      </FORM>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;