import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ServiceIndex = (props) => {

  return (
    <>
      <div className="about-us-sec sm-padding">
            <Container>
            <Row className="justify-content-md-center">
                    <Col md={8} lg={9} className="text-center">
                        <div className="section-title">
                            <h2>Storytelling like never before</h2>
                            <p>Create brand new, custom voices that can speak multiple languages in different accents.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md={12} xl={6} className="resp-mrg-btm-md">
                        <div className="about-img-sec">
                            <Image
                                className="about-img"
                                src={
                                    window.location.origin + "/img/service/service-img.png"
                                }
                                type="image/png"
                            />
                        </div>
                    </Col>
                    <Col md={12} xl={6}>
                        <div className="about-content">
                            <div className="about-us-box">
                                <div className="about-us-card">
                                    <div className="about-us-icon-sec">
                                        <Image
                                            className="about-us-icon"
                                            src={
                                                window.location.origin + "/img/service/service-icon-1.png"
                                            }
                                            type="image/png"
                                        />
                                    </div>
                                    <div className="about-us-info">
                                        <h4>Preview Mode</h4>
                                        <p>To check as many times as you want before publishing your video.</p>
                                    </div>
                                </div>
                                <div className="about-us-card">
                                    <div className="about-us-icon-sec">
                                        <Image
                                            className="about-us-icon"
                                            src={
                                                window.location.origin + "/img/service/service-icon-2.png"
                                            }
                                            type="image/png"
                                        />
                                    </div>
                                    <div className="about-us-info">
                                        <h4>Multiple languages</h4>
                                        <p>Produce spoken audio in a continuously expanding selection of languages.</p>
                                    </div>
                                </div>
                                <div className="about-us-card">
                                    <div className="about-us-icon-sec">
                                        <Image
                                            className="about-us-icon"
                                            src={
                                                window.location.origin + "/img/service/service-icon-3.png"
                                            }
                                            type="image/png"
                                        />
                                    </div>
                                    <div className="about-us-info">
                                        <h4>Intuitive interface</h4>
                                        <p>Easily create AI voiceovers and characters for your content.</p>
                                    </div>
                                </div>
                                <div className="about-us-card">
                                    <div className="about-us-icon-sec">
                                        <Image
                                            className="about-us-icon"
                                            src={
                                                window.location.origin + "/img/service/service-icon-4.png"
                                            }
                                            type="image/png"
                                        />
                                    </div>
                                    <div className="about-us-info">
                                        <h4>Multitone</h4>
                                        <p>Multitone that empowers you to take control of your narrative.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
      </div>
    </>
  );
};

export default ServiceIndex;
