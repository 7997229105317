import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import {MainLayout, AdminLayout, LandingPageLayout, StaticPageLayout} from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import AuthVerificationIndex from "../Auth/ForgetPasswordAuthVerificationIndex";
import GeneratedVoiceListIndex from "../GenerateVoice/GeneratedVoiceListIndex";
import ProfileIndex from "../Profile/ProfileIndex";
import VoiceLabListIndex from "../Voice/VoiceLabListIndex";
import GenerateVoice from "../GenerateVoice/GenerateVoiceIndex";
import ResetPassword from "../Auth/ResetPassword";
import { settingsJsonStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import {
  setTranslations,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import Page404 from "../Helper/Page404";
import ScrollToTop from "../Helper/ScrollToTop";
import StaticPagesIndex from "../StaticPages/StaticPagesIndex";

const $ = window.$;
setTranslations({ en});
const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  return (localStorage.getItem("token") || sessionStorage.getItem("token")) ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const Base = () => {
  
  const dispatch = useDispatch();
  const settingsJson = useSelector((state) => state.admin.settingsJson);
  const [skipRender, setSkipRender] = useState(true);
  
  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    dispatch(settingsJsonStart());
  }, []);

  useEffect(()=> {
    if(!skipRender && Object.keys(settingsJson.data).length > 0) {
      configuration.set({ configData: settingsJson.data }, { freeze: false });
    }
    else
      configuration.set({ configData: [] }, { freeze: false });
    setSkipRender(false);
  }, [settingsJson]);

  return settingsJson.buttonDisable ? (
    <PageLoader />
  ) : Object.keys(settingsJson.data).length > 0 ? (
    <>
      <ToastContainer />
      <Helmet>
        <title>{settingsJson.data.site_name}</title>
        <link
          rel="icon"
          type="image/png"
          href={settingsJson.data.site_icon}
        />
        <script src={settingsJson.data.header_scripts}></script>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={LandingPageIndex} layout={LandingPageLayout} />
            }
          />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={MainLayout} />}
          />
          <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={MainLayout} />}
          />
          <Route
            path={"/forgot-password"}
            element={<AppRoute component={ForgotPassword} layout={MainLayout} />}
          />
           <Route
            path={"/auth-verification"}
            element={<AppRoute component={AuthVerificationIndex} layout={MainLayout} />}
          />
          <Route
            path={"/reset-password"}
            element={<AppRoute component={ResetPassword} layout={MainLayout} />}
          />
           <Route
            path={"/generated-voice-list"}
            element={<PrivateRoute component={GeneratedVoiceListIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/profile"}
            element={<PrivateRoute component={ProfileIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/voicelab-list"}
            element={<PrivateRoute component={VoiceLabListIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/generate-voice"}
            element={<PrivateRoute component={GenerateVoice} layout={AdminLayout} />}
          />
          <Route path={"/page/:unique_id"}
            element={
              <AppRoute component={StaticPagesIndex} layout={StaticPageLayout} />
            }
          />
          <Route
            path={"/*"}
            element={<AppRoute component={Page404} layout={MainLayout}/>}
          />
        </Routes>
      </BrowserRouter>
    </>
    ) : (
      <SomethingWentWrong
        buttonText="Retry"
        handleClick={() => dispatch(settingsJsonStart())}
      />
  );
};

export default Base;
