import React, { useEffect, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import { voiceDeleteStart } from "../../store/slices/VoiceSlice";

const DeleteVoiceModal = (props) => {
  const t = useTranslation("delete_voice")
  const dispatch = useDispatch();
  const voiceDelete = useSelector((state) => state.voice.voiceDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !voiceDelete.loading && Object.keys(voiceDelete.data).length > 0) {
      props.closeDeleteVoiceModal();
    }
    setSkipRender(false);
  }, [voiceDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.deleteVoiceModal}
        onHide={props.closeDeleteVoiceModal}
        backdrop="static"
      >
        <Modal.Body>
          <Button onClick={()=> props.closeDeleteVoiceModal()} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="delete-voice-link-created-box">
            <div className="delete-voice-link-created-header-sec">
              <h3>{t("voice_title")}</h3>
              <p>{t("voice_description")}</p>
            </div>
            <div className="voice-created-body-sec">
              <div className="delete-voice-created-btn-sec">
                <Button
                  onClick={() =>
                    dispatch(
                        voiceDeleteStart({
                            voice_unique_id : props.deleteVoiceModal,
                      })
                    )
                  }
                  className="default-btn"
                  disabled={voiceDelete.buttonDisable}
                >
                  {voiceDelete.buttonDisable ? <ButtonLoader/> : t("delete") }
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteVoiceModal;
