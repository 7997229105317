import React, { useState } from "react";
import {Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const TrustedByBrandsIndex = (props) => {

  return (
    <>
      <div className="trusted-by-brands-sec sm-padding">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8} lg={9} className="text-center">
                        <div className="section-title">
                            <h2>Trusted by well-known brands</h2>
                            <p>Create brand new, custom voices that can speak multiple languages in different accents.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="trusted-by-brands-box">
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/payoneer.svg"
                                    }
                                    type="image/png"
                                />
                            </div>
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/sony.svg"
                                    }
                                    type="image/png"
                                />
                            </div>
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/tcs.svg"
                                    }
                                    type="image/png"
                                />
                            </div>
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/infosys.svg"
                                    }
                                    type="image/png"
                                />
                            </div>
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/haive.png"
                                    }
                                    type="image/png"
                                />
                            </div>
                            <div className="trusted-by-brands-card">
                                <Image
                                    className="trusted-by-brands-icon"
                                    src={
                                        window.location.origin + "/img/trust-by-brands/delhivery.svg"
                                    }
                                    type="image/png"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
      </div>
    </>
  );
};

export default TrustedByBrandsIndex;
