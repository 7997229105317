import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { voiceListSuccess,
       voiceListFailure ,
       voiceStoreSuccess,
       voiceStoreFailure,
       voiceShowSuccess,
       voiceShowFailure,
       voiceStatusSuccess,
       voiceStatusFailure,
       voiceDeleteSuccess,
       voiceDeleteFailure,
       recordVoiceCreateSuccess,
       recordVoiceCreateFailure,
       recordVoiceListFailure,
       recordVoiceListSuccess
} 
from "../slices/VoiceSlice";


function* voiceListAPI(action) {
  yield getCommonSaga({
    apiUrl: "voices",
    payload: action.payload,
    successNotify:false,
    success: voiceListSuccess,
    failure: voiceListFailure,
  });
}

function* voiceStoreAPI(action) {
  const voicesListData = yield select(state => state.voice.voiceList.data);
    yield getCommonSaga({
      apiUrl: "voices/store",
      payload: action.payload,
      successCallback: true,
      success: data => {
        store.dispatch(voiceStoreSuccess(data));
        if(Object.keys(voicesListData).length > 0 && voicesListData.voices){
          store.dispatch(voiceListSuccess({
            ...voicesListData,
            voices: action.payload.voice_unique_id ? [...voicesListData.voices.map(voice => voice.unique_id == action.payload.voice_unique_id ? data.voice : voice)] : [data.voice,...voicesListData.voices],
            total_voices: voicesListData.total_voices + 1,
          }))
        }
      },
      failure: voiceStoreFailure,
    });
  }

  function* voiceShowAPI(action) {
    yield getCommonSaga({
      apiUrl: "voices/show",
      payload: action.payload,
      successNotify:false,
      success: voiceShowSuccess,
      failure: voiceShowFailure,
    });
  }

  function* voiceStatusAPI(action) {
    yield getCommonSaga({
      apiUrl: "voices/status",
      payload: action.payload,
      success: voiceStatusSuccess,
      failure: voiceStatusFailure,
    });
  }

  function* voiceDeleteAPI(action) {
    const voiceListData = yield select(state => state.voice.voiceList.data);
    yield getCommonSaga({
      apiUrl: "voices/destroy",
      payload: action.payload,
      successCallback: true,
      success: data => {
        store.dispatch(voiceDeleteSuccess(data));
        if(Object.keys(voiceListData).length > 0 && voiceListData.voices){
          store.dispatch(voiceListSuccess({
            ...voiceListData,
            voices: voiceListData.voices.filter(item => item.unique_id != action.payload.voice_unique_id),
            total_voices: voiceListData.total_voices - 1,
          }))
        }
      },
      failure: voiceDeleteFailure,
    });
  }

  function* recordVoiceCreateAPI(action) {
    yield getCommonSaga({
      apiUrl: "phrase_voices/store",
      payload: action.payload,
      successNotify: false,
      success: recordVoiceCreateSuccess,
      failure: recordVoiceCreateFailure,
    });
  }

  function* recordVoiceListAPI(action) {
    yield getCommonSaga({
      apiUrl: "phrase_voices",
      payload: action.payload,
      successCallback: true,
      successNotify:false,
      success: data => {
        store.dispatch(recordVoiceListSuccess(data));
      },
      failure: recordVoiceListFailure,
    });
  }


export default function* VoiceSaga() {
  yield all([
    yield takeLatest('voice/voiceListStart',voiceListAPI),
    yield takeLatest('voice/voiceStoreStart', voiceStoreAPI),
    yield takeLatest('voice/voiceShowStart',voiceShowAPI),
    yield takeLatest('voice/voiceStatusStart',voiceStatusAPI),
    yield takeLatest('voice/voiceDeleteStart',voiceDeleteAPI),
    yield takeLatest('voice/recordVoiceCreateStart',recordVoiceCreateAPI),
    yield takeLatest('voice/recordVoiceListStart',recordVoiceListAPI),
  ]);
}

