import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Row, Col, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { verifyForgotPasswordStart, forgotPasswordStart } from "../../store/slices/AdminSlice";
import { useLocation } from 'react-router-dom';
import AuthSection from "./AuthSection";

const ForgetPasswordAuthVerificationIndex = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("otp_verify");
  const location = useLocation();
  const email = location.state?.email;
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword)
  const forgotPassword = useSelector((state) => state.admin.forgotPassword)
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    if(!email){
      getErrorNotificationMessage(t("invalid"));
      navigate("/forgot-password")
    }
    else{
      dispatch(verifyForgotPasswordStart({
        email: email,
        verification_code: values.otp,
      }))
    } 
  };

  const handleResendOtp = () => {
    dispatch(
      forgotPasswordStart({
        email: email
      })
    );
  };

  const otpSchema = Yup.object().shape({
    otp: Yup.string().required(t("required"))
      .min(6, t("six_digits")),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !verifyForgotPassword.loading &&
      Object.keys(verifyForgotPassword.data).length > 0
    ) {
        navigate("/reset-password")
    }
    setSkipRender(false);
  }, [verifyForgotPassword]);

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <AuthSection/>
          <div className="auth-right-sec">
            <div className="auth-right-box">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <Formik
                    initialValues={{
                      otp: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={otpSchema}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="auth-form-sec">
                        <div className="verification-item">
                          <h4>{t("otp_verification")}</h4>
                          <OtpInput
                            value={values.otp}
                            onChange={(value) => {
                              const numericValue = value.replace(/\D/g, '');
                              setFieldValue('otp', numericValue)
                            }}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="otp"
                          className="errorMsgAuthVerification"
                        />
                        <div className="verification-btn-sec">
                          <span>{t("not_getting_code")}</span>
                          <button
                            type="button"
                            onClick={handleResendOtp}
                            className="resend-link"
                            disabled={
                              forgotPassword.buttonDisable ||
                              forgotPassword.buttonDisable
                            }
                          >
                            {forgotPassword.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("resend_otp")
                            )}
                          </button>
                        </div>
                        <div className="verify-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn"
                            disabled={
                              verifyForgotPassword.buttonDisable
                            }
                          >
                            {verifyForgotPassword.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("validate")
                            )}
                          </Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordAuthVerificationIndex;