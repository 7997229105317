import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";


const initialState = {
    theme: "",
    phraseList: getCommonInitialState(),
};

const PhraseSlice = createSlice({
    name: "phrase",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //phraseList
        phraseListStart: (state, action) => { state.phraseList = getCommonStart() },
        phraseListSuccess: (state, action) => { state.phraseList = getCommonSuccess(action.payload) },
        phraseListFailure: (state, action) => { state.phraseList = getCommonFailure(action.payload) }

    }
});

export const {
    setTheme,
    phraseListStart,
    phraseListSuccess,
    phraseListFailure,
} = PhraseSlice.actions;

export default PhraseSlice.reducer;