import React, { useState, useEffect,useRef } from "react";
import { Button, Image, InputGroup, Row, Col } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { ButtonLoader } from "../Helper/Loader";
import { forgotPasswordStart } from "../../store/slices/AdminSlice";
import AuthSection from "./AuthSection";

const ForgotPassword = () => {
  const formRef=useRef();
  const t = useTranslation("forgot");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const [skipRender, setSkipRender] = useState(true);
 
  const handleForgot = (values)=>{
    dispatch(forgotPasswordStart(values))
  }

  useEffect(() => {
    if (
      !skipRender &&
      !forgotPassword.loading &&
      Object.keys(forgotPassword.data).length > 0
    ) {
      navigate('/auth-verification', { state: { email: formRef.current.values.email } });
    }
    setSkipRender(false);
  }, [forgotPassword]);

  const forgotSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  return (
    <>
      <div className="auth-sec">
        <div className="auth-box">
          <AuthSection/>
          <div className="auth-right-sec">
            <div className="auth-right-box">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                   validationSchema={forgotSchema}
                     onSubmit={handleForgot}
                     innerRef={formRef}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="auth-form-sec">
                        <div className="auth-form-card">
                          <h3>{t("heading")}</h3>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#878E96" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                            </InputGroup.Text>
                            <Field
                              className="form-control"
                              placeholder={t("email.placeholder")}
                              type="email"
                              autoFocus={true}
                              name="email"
                            />
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                          <div className="auth-btn-sec">
                            <Button type="submit" className="default-btn" disabled={forgotPassword.buttonDisable}>
                              {forgotPassword.buttonDisable ? (
                                <ButtonLoader varient="black" />
                              ) : (
                                t("submit_btn.text")
                              )}
                            </Button>
                          </div>
                          <div className="auth-footer-sec">
                            <Link to="/login">
                              {t("cancel")}
                            </Link>
                          </div>
                        </div>
                        <div className="auth-review-sec">
                          <p>
                            {t("review")}
                            <Link to="#">{t("terms_condition")}</Link>
                            {t("and")}
                            <Link to="#">{t("privacy_policy")}</Link>
                          </p>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
