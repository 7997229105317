import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

const AuthSection = () => {
  const t = useTranslation("login");

  return (
    <div className="auth-left-sec">
      <div className="auth-left-card">
        <div className="auth-middle-content">
          <Image
            className="auth-left-img"
            src={window.location.origin + "/img/auth/auth-left-img.png"}
            type="image/png"
          />
          <h3>{t("message")}</h3>
          <p>{t("image_content")}</p>
        </div>
        <div className="auth-bottom-logo">
          <Link to="/">
            <Image
              className="logo"
              src={window.location.origin + "/img/auth/auth-logo.png"}
              type="image/png"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthSection;
