import React, { useEffect, useState } from "react";
import AuthPageHeader from "./Header/AuthPageHeader";
import LandingPageHeader from "./Header/LandingPageHeader";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/slices/AdminSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import LandingFooterIndex from "./Footer/LandingFooterIndex";

const MainLayout = (props) => {
  return (
    <>
      <div className="main-wrapper">{props.children}</div>
    </>
  );
};

const AdminLayout = (props) => {

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!Object.keys(profile.data).length > 0) dispatch(profileStart());
  }, []);

  return (
    <>
      {profile.loading ? (
        <PageLoader />
      ) : Object.keys(profile.data).length > 0 ? (
        <>
          <AuthPageHeader />
          <div className="main-wrapper">{props.children}</div>
          {/* <EmptyFooter /> */}
        </>
      ) : (
        <div className="main-wrapper">
          <SomethingWentWrong
            buttonText="Retry"
            handleClick={() => dispatch(profileStart())}
          />
        </div>
      )}
    </>
  );
};

const LandingPageLayout = (props) => {

  const token = localStorage?.getItem("token") || sessionStorage?.getItem("token");

  return (
    <>
      {!token ? <LandingPageHeader /> : <AuthPageHeader />}
      <div className="main-wrapper">{props.children}</div>
      <LandingFooterIndex/>
    </>
  );
};

const StaticPageLayout = (props) => {

  const profile = useSelector((state) => state.admin.profile);
  const token = localStorage?.getItem("token") || sessionStorage?.getItem("token");

  return (
    <>
      {profile.buttonDisable ? (
        <PageLoader />
      ) : (Object.keys(profile.data).length > 0 && token) ? (
        <>
          <AuthPageHeader />
          <div className="main-wrapper">{props.children}</div>
        </>
      ) : (
        <>
          <LandingPageHeader />
          <div className="main-wrapper">{props.children}</div>
          <LandingFooterIndex/>
        </>
      )}
    </>
  )
}

export { MainLayout, AdminLayout, LandingPageLayout, StaticPageLayout };
